/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';

//* EXTERNAL LIBS --> MUI

import { Grid, Input as CoreInput } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { Select, Checkbox, DatePicker } from 'xpand-ui/forms';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './yupSchema';
import { isAfter, isBefore, parseISO, startOfDay } from 'date-fns';

//* COMPONENT INTERFACES

//* COMPONENT
const Filters: FC<any> = ({ originalData, data, setData, setTabSelected, isTimeOff }) => {
	const [employeeList, setEmployeesList] = useState();
	const [timeoffTypesList, setTimeOffTypesList] = useState([]);
	const {
		control,
		formState: { errors },
		watch,
		getValues,
		setValue,
		reset
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: defaultValues,
		shouldUnregister: false
	});
	// Set Employees List in Filters
	useEffect(() => {
		let uniqueNamesSet: any = []; // Use Set to store unique names
		uniqueNamesSet.push({ id: 'all', label: 'All' });
		// Step 2: Loop through each key in the results object
		Object.values(originalData).forEach(array => {
			if (Array.isArray(array)) {
				array.forEach(item => {
					if (item.employeeName || item.fullName) {
						// Check if the object {id, label} already exists in allNames
						const exists = uniqueNamesSet.some(
							existing => existing.label === item.employeeName || existing.label === item.fullName
						);
						if (!exists) {
							uniqueNamesSet.push({
								id: item.employeeName || item.fullName,
								label: item.employeeName || item.fullName
							});
						}
					}
				});
			}
		});
		setEmployeesList(uniqueNamesSet);

		if (timeoffTypesList?.length === 0) {
			let timeOffTypes: any = [];
			timeOffTypes.push({ id: 'all', label: 'All' });
			originalData?.accountTimeOffTypes?.map(e => {
				timeOffTypes.push({ id: e.accountTimeOffTypeId, label: e.accountTimeOffType });
			});
			setTimeOffTypesList(timeOffTypes);
		}
	}, [originalData]);

	//Removes summer time from dates
	function toUTCDate(date) {
		return new Date(
			Date.UTC(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds(),
				date.getMilliseconds()
			)
		);
	}

	// Set Employees List in Filters
	useEffect(() => {
		if (data) {
			let filteredData = Object.fromEntries(
				Object.entries(originalData).map(([key, array]) => {
					// Check if the value is an array
					if (Array.isArray(array)) {
						// Filter the array and map the elements
						const filteredArray = array.filter(item => {
							const employee = getValues('employee');
							const timeOffType = getValues('typeTimeoff');
							// Uncomment the following lines if you need to filter by date
							const start = getValues('startDate')
								? toUTCDate(parseISO(getValues('startDate')))
								: new Date('1753-01-01');
							const end = getValues('endDate')
								? toUTCDate(parseISO(getValues('endDate')))
								: new Date('9999-12-31');
							if (
								isBefore(
									startOfDay(parseISO(item.timeEntryDate || item.expenseSheetDate || item.startDate)),
									startOfDay(start)
								) ||
								isAfter(
									startOfDay(parseISO(item.timeEntryDate || item.expenseSheetDate || item.endDate)),
									startOfDay(end)
								)
							) {
								return false;
							}
							if (timeOffType !== '' && timeOffType === 'all') {
								return true;
							}
							if (timeOffType !== item.accountTimeOffTypeId) {
								return false;
							}
							if (employee !== '' && employee === 'all') {
								return true;
							}
							// Filter by employee name if it's not empty
							if (employee !== '' && employee !== item.employeeName && employee !== item.fullName) {
								return false;
							}

							return true;
						});

						// Return the filtered array for this key
						return [key, filteredArray];
					}

					// If the value is not an array, return it unchanged
					return [key, array];
				})
			);
			setData(filteredData);
			setTabSelected(0);
		}
	}, [watch('employee'), watch('startDate'), watch('endDate'), watch('typeTimeoff')]);

	return (
		<>
			<Grid
				container
				direction={'row'}
				sx={{
					justifyContent: 'space-evenly',
					alignItems: 'center'
				}}>
				<Grid item xs={3}>
					<Select
						name="employee"
						label="EMPLOYEE NAME"
						labelBold
						options={employeeList || []}
						onChange={(e: any) => {
							setValue('employee', e);
						}}
						hasSearchOption
						control={control}
						errors={errors}
					/>
				</Grid>
				{isTimeOff && (
					<Grid item xs={3}>
						<Select
							name="typeTimeoff"
							label="TIME OFF TYPES"
							labelBold
							options={timeoffTypesList || []}
							onChange={(e: any) => {
								setValue('typeTimeoff', e);
							}}
							hasSearchOption
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				<Grid item xs={3}>
					<DatePicker
						name="startDate"
						labelBold
						label="Start Date"
						format="DD/MM/YYYY"
						control={control}
						errors={errors}
					/>
				</Grid>
				<Grid item xs={3}>
					<DatePicker
						labelBold
						name="endDate"
						label="End Date"
						format="DD/MM/YYYY"
						control={control}
						errors={errors}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default withLayout(Filters);
