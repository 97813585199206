import { ISidebarGroupItem } from 'xpand-ui/core';

import { pages } from './pages';

export const generic: ISidebarGroupItem[] = [
	{
		label: pages.apps.label,
		icon: pages.apps.icon,
		path: pages.apps.path,
		roles: pages.apps.roles,
		subMenu: undefined,
		allowExternalAccess: true,
		submenuLevel: 1
	}
];

export const personalInfo: ISidebarGroupItem[] = [
	{
		label: pages.profile.label,
		icon: pages.profile.icon,
		path: pages.profile.path,
		roles: pages.profile.roles,
		subMenu: [
			{
				label: pages.personalFiscal.label,
				path: pages.personalFiscal.path,
				roles: pages.personalFiscal.roles,
				subMenu: undefined,
				allowExternalAccess: true,
				submenuLevel: 2,
				father: pages.profile.label
			},
			{
				label: pages.consentInformation.label,
				path: pages.consentInformation.path,
				roles: pages.consentInformation.roles,
				subMenu: undefined,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.profile.label
			},
			{
				label: pages.career.label,
				path: pages.career.path,
				roles: pages.career.roles,
				subMenu: undefined,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.profile.label
			},
			{
				label: pages.skills.label,
				path: pages.skills.path,
				roles: pages.skills.roles,
				subMenu: [],
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.profile.label
			},
			{
				label: pages.resume.label,
				path: pages.resume.path,
				roles: pages.resume.roles,
				subMenu: undefined,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.profile.label
			},
			{
				label: pages.trainings.label,
				path: pages.trainings.path,
				roles: pages.trainings.roles,
				subMenu: undefined,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.profile.label
			}
		],
		allowExternalAccess: true,
		submenuLevel: 1
	},
	{
		label: pages.xtracker.label,
		icon: pages.xtracker.icon,
		path: pages.xtracker.path,
		subMenu: [
			{
				label: pages.xtrackerTimesheets.label,
				path: pages.xtrackerTimesheets.path,
				roles: pages.xtrackerTimesheets.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.xtracker.label
			}
			// {
			// 	label: pages.xtrackerTimeoffs.label,
			// 	path: pages.xtrackerTimeoffs.path,
			// 	roles: pages.xtrackerTimeoffs.roles,
			// 	allowExternalAccess: true,
			// 	submenuLevel: 2,
			// 	father: pages.xtracker.label
			// }
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	{
		label: pages.financial.label,
		icon: pages.financial.icon,
		path: pages.financial.path,
		roles: pages.financial.roles,
		subMenu: [
			{
				label: pages.financialDocuments.label,
				path: pages.financialDocuments.path,
				roles: pages.financialDocuments.roles,
				subMenu: undefined,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.financial.label
			},
			{
				label: pages.benefits.label,
				path: pages.benefits.path,
				roles: pages.benefits.roles,
				subMenu: undefined,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.financial.label
			},
			{
				label: pages.xtrackerMyExpenses.label,
				path: pages.xtrackerMyExpenses.path,
				roles: pages.xtrackerMyExpenses.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.financial.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	{
		label: pages.myCompany.label,
		icon: pages.myCompany.icon,
		path: pages.myCompany.path,
		roles: pages.myCompany.roles,
		subMenu: [
			{
				label: pages.myCompanyOrgChart.label,
				path: pages.myCompanyOrgChart.path,
				roles: pages.myCompanyOrgChart.roles,
				allowExternalAccess: true,
				submenuLevel: 2,
				father: pages.myCompany.label
			},
			{
				label: pages.myCompanyAllEmployees.label,
				path: pages.myCompanyAllEmployees.path,
				roles: pages.myCompanyAllEmployees.roles,
				allowExternalAccess: true,
				submenuLevel: 2,
				father: pages.myCompany.label
			},
			{
				label: pages.myCompanyMyTeam.label,
				path: pages.myCompanyMyTeam.path,
				roles: pages.myCompanyMyTeam.roles,
				allowExternalAccess: true,
				submenuLevel: 2,
				father: pages.myCompany.label
			}
		],
		allowExternalAccess: true,
		submenuLevel: 1
	},

	{
		label: pages.bookings.label,
		icon: pages.bookings.icon,
		path: pages.bookings.path,
		roles: pages.bookings.roles,
		subMenu: undefined,
		allowExternalAccess: false,
		submenuLevel: 1
	}
];

export const administration: ISidebarGroupItem[] = [
	//! start USERS
	{
		label: pages.employees.label,
		icon: pages.employees.icon,
		path: pages.employees.path,
		roles: pages.employees.roles,
		subMenu: [
			{
				label: pages.newEmployee.label,
				path: pages.newEmployee.path,
				roles: pages.newEmployee.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.employees.label
			},
			{
				label: pages.listEmployees.label,
				path: pages.listEmployees.path,
				roles: pages.listEmployees.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.employees.label
			},
			{
				label: pages.vpnAccess.label,
				path: pages.vpnAccess.path,
				roles: pages.vpnAccess.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.employees.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end USERS
	//! start ADMISSIONS AND RESIGNATIONS
	{
		label: pages.admissionsAndResignations.label,
		icon: pages.admissionsAndResignations.icon,
		path: pages.admissionsAndResignations.path,
		roles: pages.admissionsAndResignations.roles,
		subMenu: [
			{
				label: pages.resignationManagement.label,
				path: pages.resignationManagement.path,
				roles: pages.resignationManagement.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.admissionsAndResignations.label
			},
			{
				label: pages.newAdmission.label,
				path: pages.newAdmission.path,
				roles: pages.newAdmission.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.admissionsAndResignations.label
			},
			{
				label: pages.admissionsManagement.label,
				path: pages.admissionsManagement.path,
				roles: pages.admissionsManagement.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.admissionsAndResignations.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end ADMISSIONS AND RESIGNATIONS
	//! start SALARY AND CONTRACT MANAGEMENT
	{
		label: pages.salariesAndContracts.label,
		icon: pages.salariesAndContracts.icon,
		path: pages.salariesAndContracts.path,
		roles: pages.salariesAndContracts.roles,
		subMenu: [
			{
				label: pages.employeeSalaryCategories.label,
				path: pages.employeeSalaryCategories.path,
				roles: pages.employeeSalaryCategories.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.salariesAndContracts.label
			},
			{
				label: pages.contractManagement.label,
				path: pages.contractManagement.path,
				roles: pages.contractManagement.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.salariesAndContracts.label
			},
			{
				label: pages.collaborationTypesManagment.label,
				path: pages.collaborationTypesManagment.path,
				roles: pages.collaborationTypesManagment.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.salariesAndContracts.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end SALARY AND CONTRACT MANAGEMENT
	//! start RECRUITMENT
	{
		label: pages.recruitment.label,
		icon: pages.recruitment.icon,
		path: pages.recruitment.path,
		roles: pages.recruitment.roles,
		subMenu: [
			{
				label: pages.newProposal.label,
				path: pages.newProposal.path,
				roles: pages.newProposal.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.recruitment.label
			},
			{
				label: pages.proposals.label,
				path: pages.proposals.path,
				roles: pages.proposals.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.recruitment.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end RECRUITMENT
	//! start FINANCIAL MANAGEMENT
	{
		label: pages.financialManagement.label,
		icon: pages.financialManagement.icon,
		path: pages.financialManagement.path,
		roles: pages.financialManagement.roles,
		subMenu: [
			{
				label: pages.budget.label,
				path: pages.budget.path,
				roles: pages.budget.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.financialManagement.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end FINANCIAL MANAGEMENT
	{
		label: pages.orgManagement.label,
		icon: pages.orgManagement.icon,
		path: pages.orgManagement.path,
		roles: pages.orgManagement.roles,
		subMenu: [
			// {
			// 	label: pages.orgStructure.label,
			// 	path: pages.orgStructure.path,
			// 	roles: pages.orgStructure.roles,
			// 	allowExternalAccess: false,
			// 	submenuLevel: 2
			// },
			{
				label: pages.projects.label,
				path: pages.projects.path,
				roles: pages.projects.roles,
				subMenu: [
					// {
					// 	label: pages.listProjects.label,
					// 	path: pages.listProjects.path,
					// 	roles: pages.listProjects.roles,
					// 	allowExternalAccess: false,
					// 	submenuLevel: 3
					// },
					{
						label: pages.newProject.label,
						path: pages.newProject.path,
						roles: pages.newProject.roles,
						allowExternalAccess: false,
						submenuLevel: 3
					}
				],
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.orgManagement.label
			},
			{
				label: pages.clients.label,
				path: pages.clients.path,
				roles: pages.clients.roles,
				subMenu: [
					{
						label: pages.newClient.label,
						path: pages.newClient.path,
						roles: pages.newClient.roles,
						allowExternalAccess: false,
						submenuLevel: 3
					}
				],
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.orgManagement.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! start ORGANIZATIONAL MANAGEMENT
	{
		label: pages.skillsAndResumes.label,
		icon: pages.skillsAndResumes.icon,
		path: pages.skillsAndResumes.path,
		roles: pages.skillsAndResumes.roles,
		subMenu: [
			{
				label: pages.manageSkills.label,
				path: pages.manageSkills.path,
				roles: pages.manageSkills.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.skillsAndResumes.label
			},
			{
				label: pages.skillsEvaluation.label,
				path: pages.skillsEvaluation.path,
				roles: pages.skillsEvaluation.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.skillsAndResumes.label
			},
			{
				label: pages.searchResumes.label,
				path: pages.searchResumes.path,
				roles: pages.searchResumes.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.skillsAndResumes.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end SKILLS AND RESUMES
	//! start PLANNER
	{
		label: pages.planner.label,
		icon: pages.planner.icon,
		path: pages.planner.path,
		roles: pages.planner.roles,
		subMenu: undefined,
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end PLANNER
	//! start SYSTEMS MANAGEMENT
	{
		label: pages.systemsManagement.label,
		icon: pages.systemsManagement.icon,
		path: pages.systemsManagement.path,
		roles: pages.systemsManagement.roles,
		subMenu: [
			{
				label: pages.vpnAccess.label,
				path: pages.vpnAccess.path,
				roles: pages.vpnAccess.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.systemsManagement.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end SYSTEMS MANAGEMENT
	//! start APPROVALS
	{
		label: pages.approvals.label,
		icon: pages.approvals.icon,
		path: pages.approvals.path,
		roles: pages.approvals.roles,
		subMenu: [
			{
				label: pages.timesheets.label,
				path: pages.timesheets.path,
				roles: pages.timesheets.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.approvals.label
			},
			{
				label: pages.expenses.label,
				path: pages.expenses.path,
				roles: pages.expenses.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.approvals.label
			},
			{
				label: pages.timeOff.label,
				path: pages.timeOff.path,
				roles: pages.timeOff.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.approvals.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	},
	//! end APPROVALS
	//! start ADMINISTRATIOM
	{
		label: pages.administration.label,
		icon: pages.administration.icon,
		path: pages.administration.path,
		roles: pages.administration.roles,
		subMenu: [
			{
				label: pages.accessControl.label,
				path: pages.accessControl.path,
				roles: pages.accessControl.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.emailTemplates.label,
				path: pages.emailTemplates.path,
				roles: pages.emailTemplates.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.cacheManagement.label,
				path: pages.cacheManagement.path,
				roles: pages.cacheManagement.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.fileTemplates.label,
				path: pages.fileTemplates.path,
				roles: pages.fileTemplates.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.releaseNotes.label,
				path: pages.releaseNotes.path,
				roles: pages.releaseNotes.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.jobExecutions.label,
				path: pages.jobExecutions.path,
				roles: pages.jobExecutions.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.roles.label,
				path: pages.roles.path,
				roles: pages.roles.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.appLinks.label,
				path: pages.appLinks.path,
				roles: pages.appLinks.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.users.label,
				path: pages.users.path,
				roles: pages.users.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			},
			{
				label: pages.userGuides.label,
				path: pages.userGuides.path,
				roles: pages.userGuides.roles,
				allowExternalAccess: false,
				submenuLevel: 2,
				father: pages.administration.label
			}
		],
		allowExternalAccess: false,
		submenuLevel: 1
	}
	//! end ADMINISTRATION
];
