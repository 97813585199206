//* EXTERNAL LIBS
import React, {
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
	FC,
	useEffect,
	useMemo,
	useRef,
	Fragment
} from 'react';
//* EXTERNAL LIBS --> MUI
import { Badge, Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { AccessTime, CalendarToday, MoreVert, TableChartOutlined } from '@mui/icons-material';
import ArrowLeft from '../../../../assets/icons/arrow-left-1.svg';
import ArrowRight from '../../../../assets/icons/arrow-right-1.svg';
//* EXTERNAL LIBS --> Mobiscroll
import { CalendarNav, Datepicker, Popup, momentTimezone, setOptions } from '@mobiscroll/react';
import moment from 'moment-timezone';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Toggler, Subtitles } from 'xpand-ui/core';
import { GoToCard, RightSidebar, Dialog, Table, BackPage } from 'xpand-ui/core';
import { Select as LibSelect } from 'xpand-ui/forms';

//* TYPINGS
import { IUserToken } from 'typings/store/generalTypes';
import { ITitle } from 'components/App/TitleProvider';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { MyTimeoffProps } from '.';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './MyTimeoff.css';
import {
	hasTwoDaysOfDifference,
	isOverlap,
	hexToRGBA,
	statusColor,
	convertDateToDDMMYYYY,
	convertDateToDDMMYY,
	dateIsTimeoffRequest,
	scrollToCard
} from './utils';
import { addNotification } from 'lib/utils/notifications';
import { ITableColumn } from 'typings/store/ComponentLib';
import TimeOffRequestModal from './TimeOffRequestModal';
import { startOfMonth } from 'date-fns';
import { setSaveTimeoff } from 'store/personalInfo/actions';
import { useDispatch } from 'react-redux';
import { refreshPage } from '../MyTimesheets/utils';
import ViewDetailsModal from './ViewDetailsModal';
import { ITimeoffRequest } from 'typings/store/personalInfoTypes';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

//* COMPONENT INTERFACES
// Interface for MyTimeoff component props
interface IMyTimeoff extends MyTimeoffProps {
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
	title: ITitle;
	setTitle: Dispatch<SetStateAction<ITitle>>;
}

// Set Mobiscroll options
setOptions({
	theme: 'material',
	themeVariant: 'light'
});

//* COMPONENT
const MyTimeoff: FC<IMyTimeoff> = ({
	loggedUser,
	personalInfo,
	getTimeoffData,
	saveTimeoff,
	deleteTimeoff,
	submitTimeoff,
	unsubmitTimeoff,
	getTimeoffRequestApprovalDetailsData,
	exportICALTimeOff,
	exportPDFTimeOff
}) => {
	const classes = useStyles();
	momentTimezone.moment = moment;
	const dispatch = useDispatch();
	const datePickerRef = useRef(null);
	const contentRefs = useRef(null);
	const cardRefs = useRef(null);
	const { loading, timeoffData, saveTimeoffMessage, timeOffRequestApprovalDetailData } = personalInfo;
	const [anchorElHeaderAction, setAnchorElHeaderAction] = useState<null | HTMLElement>(null);
	//Filters
	const [openFiltersModal, setOpenFiltersModal] = useState(false);
	const [filterEmployee, setFilterEmployee] = useState(loggedUser.username);
	const [isDifferentUserOpened, setDifferentUserOpened] = useState(false);
	// ToolTip Cell
	const [isOpenToolTip, setIsOpenToolTip] = useState(false);
	const [anchor, setAnchor] = React.useState(null);
	const [timeoffRequest, setTimeoffRequest] = useState('');
	const [timeoffRequestColor, setTimeoffRequestColor] = useState('');
	const [statusToolTip, setStatusToolTip] = useState('');
	// Toogle States
	const [currentToggle, setCurrentToggle] = useState('calendar');
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDateAux, setSelectedDateAux] = useState(new Date());
	// Right Sidebar
	const [isRightBarOpen, setIsRightBarOpen] = useState(true);
	const [isAfterSave, setIsAfterSave] = useState(false);
	const [dateAfterSave, setDateAfterSave] = useState<any>(null);
	// Timeoff Actions
	const [timeoffActionModal, setTimeoffActionModal] = useState(false);
	const [timeoffAction, setTimeoffAction] = useState('');
	const [selectedTimeOffRequest, setSelectedTimeOffRequest] = useState<ITimeoffRequest | null>(null);
	const [openViewDetails, setOpenViewDetails] = useState(false);
	const [isAfterCardAction, setIsAfterCardAction] = useState(false);

	const { width } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 2100, [width]); // default is 2100 tablet
	const isTabletBetween = useMemo(() => width <= 2100 && width >= 1800, [width]); // default is 900 tablet

	//Allows to open and close the right side bar
	const openCloseRightSideBar = () => {
		setIsRightBarOpen(!isRightBarOpen);
	};

	// useState used for all TimeOff Request ranges and customize style of any cell
	const [timeoffRequests, setTimeoffRequests] = useState([
		{
			recurring: {
				repeat: 'weekly',
				weekDays: 'SA,SU'
			},
			cellCssClass: 'weekend'
		}
	]);

	// useState used for all TimeOff Request status ("Approved", "Rejected", "Submitted" ou "Saved")
	const [statusUnderlines, setStatusUnderlines] = useState([]);

	// Time Off Request Form Modal
	const [isTimeOffRequestModalOpen, setTimeOffRequestModalOpen] = useState(false);
	const [isEditingTimeoffRequest, setEditingTimeoffRequest] = useState(false);
	const [timeOffRequestStartDate, setTimeOffRequestStartDate] = useState(null);
	const [timeOffRequestEndDate, setTimeOffRequestEndDate] = useState(null);

	// Status footer
	const subtitles = [
		{
			color: statusColor('Not Submitted'),
			text: 'Not Submitted'
		},
		{
			color: statusColor('Submitted'),
			text: 'Submitted'
		},
		{
			color: statusColor('Approved'),
			text: 'Approved'
		},
		{
			color: statusColor('Rejected'),
			text: 'Rejected'
		}
	];

	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'color',
				width: '3%',
				format: row => (
					<div
						style={{
							width: '20px',
							height: '20px',
							backgroundColor: row.color,
							borderRadius: '5px'
						}}></div>
				)
			},
			{
				label: 'Time Off Type',
				id: 'accountTimeOffType',
				width: '15%'
			},
			{
				label: 'Description',
				id: 'briefExplanation',
				width: '35%'
			},
			{
				label: 'Paid',
				id: 'paid',
				align: 'center',
				width: '5%',
				format: row => (
					<>
						{row.paid && <label>Yes</label>}
						{!row.paid && <label>No</label>}
					</>
				)
			},
			{
				label: 'Schedule on Non Work Days',
				id: 'scheduleWeekends',
				width: '5%',
				align: 'center',
				format: row => (
					<>
						{row.scheduleWeekends && <label>Yes</label>}
						{!row.scheduleWeekends && <label>No</label>}
					</>
				)
			},
			{
				label: 'Earned Credits (Days)',
				id: 'earned',
				width: '5%',
				align: 'center',
				format: (row, text: number) => (Number(text / 8) + ' ').replace('.', ',')
			},
			{
				label: 'Carry Previous Year',
				id: 'carryForward',
				width: '5%',
				align: 'center',
				format: (row, text: number) => (Number(text / 8) + ' ').replace('.', ',')
			},
			{
				label: 'Scheduled (Saved)',
				id: 'savedHours',
				width: '5%',
				align: 'center',
				color: statusColor('Not Submitted'),
				format: (row, text: number) => (Number(text / 8) + ' ').replace('.', ',')
			},
			{
				label: 'Scheduled (Submitted)',
				id: 'requestedHours',
				width: '5%',
				align: 'center',
				color: statusColor('Submitted'),
				format: (row, text: number) => (Number(text / 8) + ' ').replace('.', ',')
			},
			{
				label: 'Scheduled (Approved)',
				id: 'approvedHours',
				width: '5%',
				align: 'center',
				color: statusColor('Approved'),
				format: (row, text: number) => (Number(text / 8) + ' ').replace('.', ',')
			},
			{
				label: 'Rejected',
				id: 'rejectedHours',
				width: '5%',
				align: 'center',
				color: statusColor('Rejected'),
				format: (row, text: number) => (Number(text / 8) + ' ').replace('.', ',')
			},
			{
				label: 'Available Credits (Days)',
				id: 'available',
				align: 'center',
				width: '5%',
				format: (row, text: number) => (Number(text / 8) + ' ').replace('.', ',')
			}
		],
		[]
	);

	let tableData =
		currentYear === new Date().getFullYear()
			? timeoffData?.statusCreditsCurrentYear
			: timeoffData?.statusCreditsPreviousYears
					?.filter(e => Number(e.lastEarnedDate) === currentYear)
					.map(e => ({
						...e,
						requestedHours: e.submittedHours
					}));

	//Load All TimeOff page data
	useEffect(() => {
		getTimeoffData({ username: loggedUser.username });
	}, []);

	//Reload After Save/Submit Timeoff
	useEffect(() => {
		if (saveTimeoffMessage?.message) {
			//Reset Page
			dispatch(setSaveTimeoff(null));
			setTimeoffRequests([
				{
					recurring: {
						repeat: 'weekly',
						weekDays: 'SA,SU'
					},
					cellCssClass: 'weekend'
				}
			]);
			setStatusUnderlines([]);
			setTimeOffRequestModalOpen(false);
			getTimeoffData({ username: loggedUser.username });
		}
	}, [saveTimeoffMessage]);

	//Closed Right Side bar if empty
	useEffect(() => {
		const hasCardInfos = prepareGoToCardInfo();
		if (isAfterSave) {
			// Scrolls rightbar to the new saved card
			setIsRightBarOpen(true);
			//! HTML DOM paths that can change if the Right Sidebar component is modified
			const cards = cardRefs?.current?.children[1].children[0].children[0].children[0].children[1].children;
			const scrollableElement = cardRefs?.current?.children[1].children[0].children[0];
			const pageContentElement = contentRefs?.current?.parentNode?.parentNode;
			scrollToCard(dateAfterSave, cards, scrollableElement, pageContentElement);
			setIsAfterSave(false);
			setDateAfterSave(null);
		} else if (isAfterCardAction) {
			setIsAfterCardAction(false);
			setIsRightBarOpen(true);
		} else if (!hasCardInfos) {
			setIsRightBarOpen(false);
		}
	}, [timeoffData]);

	//Use to clear time off requests and underlines customization to refill them with new data
	useEffect(() => {
		if (isDifferentUserOpened) {
			setTimeoffRequests([
				{
					recurring: {
						repeat: 'weekly',
						weekDays: 'SA,SU'
					},
					cellCssClass: 'weekend'
				}
			]);
			setStatusUnderlines([]);
		}
	}, [timeoffData, isDifferentUserOpened]);

	// Fill Calendar with all TimeOff Requests records
	useEffect(() => {
		if (timeoffData?.accountEmployeeTimeoffRequests) {
			timeoffData.accountEmployeeTimeoffRequests.forEach(request => {
				const startDate = new Date(request.startDate);
				const endDate = new Date(request.endDate);
				startDate.setHours(1, 0, 0, 0);
				endDate.setHours(1, 0, 0, 0);
				const startEvent = {
					date: startDate,
					cellCssClass: request.hoursOff < 8 ? 'halfDay-range' : 'solo-range',
					backgroundAux: request.color,
					approvalStatus: request.approvalStatus,
					accountTimeOffType: request.accountTimeOffType,
					statusColor: statusColor(request?.approvalStatus),
					createOnPrevYear: startDate.getFullYear() > new Date(request.createdOn).getFullYear(),
					createOn: request.createdOn,
					accountEmployeeTimeOffRequestId: request.accountEmployeeTimeOffRequestId
				};
				const endEvent = {
					date: endDate,
					cellCssClass: 'end-range',
					backgroundAux: request.color,
					approvalStatus: request.approvalStatus,
					accountTimeOffType: request.accountTimeOffType,
					statusColor: statusColor(request?.approvalStatus),
					createOnPrevYear: endDate.getFullYear() > new Date(request.createdOn).getFullYear(),
					createOn: request.createdOn,
					accountEmployeeTimeOffRequestId: request.accountEmployeeTimeOffRequestId
				};

				if (startDate.getTime() !== endDate.getTime()) {
					startEvent.cellCssClass = 'start-range';
				}

				setTimeoffRequests(prevData => {
					const newData = [...prevData, startEvent];
					if (startDate.getTime() !== endDate.getTime()) {
						newData.push(endEvent);
					}

					if (hasTwoDaysOfDifference(startEvent.date, endDate)) {
						const rangeStart = new Date(startEvent.date);
						rangeStart.setDate(rangeStart.getDate() + 1);
						const rangeEnd = new Date(endDate);
						rangeEnd.setDate(rangeEnd.getDate() - 1);
						newData.push({
							start: rangeStart,
							end: rangeEnd,
							cellCssClass: 'range',
							backgroundAux: request.color,
							approvalStatus: request.approvalStatus,
							accountTimeOffType: request.accountTimeOffType,
							statusColor: statusColor(request?.approvalStatus),
							createOnPrevYear: startDate.getFullYear() > new Date(request.createdOn).getFullYear(),
							createOn: request.createdOn,
							accountEmployeeTimeOffRequestId: request.accountEmployeeTimeOffRequestId
						});
					}

					return newData;
				});

				if (statusUnderlines.length == 0) {
					setStatusUnderlines(prevData => {
						const newData = [
							...prevData,
							{
								start: startDate,
								end: endDate,
								text: '',
								color: statusColor(request?.approvalStatus)
							}
						];
						return newData;
					});
				}
			});
		}
		if (timeoffData?.accountEmployeeHolidayTypes) {
			timeoffData.accountEmployeeHolidayTypes.forEach(holiday => {
				setTimeoffRequests(prevData => {
					const newData = [...prevData];
					newData.push({
						date: new Date(holiday?.holidayDate),
						cellCssClass: 'solo-range',
						backgroundAux: '#dee5ef',
						approvalStatus: 'Holiday',
						accountTimeOffType: holiday.holidayNmae,
						statusColor: '#006aac',
						createOnPrevYear: false,
						createOn: null
					});

					return newData;
				});
			});
		}
	}, [timeoffData, hasTwoDaysOfDifference]);

	// Fill Calendar with all TimeOff Requests records
	useEffect(() => {
		const styleTag = document.getElementById('dynamic-style');
		if (!styleTag) {
			const newStyleTag = document.createElement('style');
			newStyleTag.id = 'dynamic-style';
			document.head.appendChild(newStyleTag);
		}
		const updatedStyleTag = document.getElementById('dynamic-style');
		const styles = timeoffRequests
			.map((timeoffRequest, index) => {
				const hexColor = timeoffRequest.backgroundAux;
				const startStyle = `
                .custom-start-range-${index}:not(.mbsc-calendar-day-empty) {
                    background: linear-gradient(to bottom, transparent 0.30em, ${hexColor} 0.30em 2em, transparent 2em ) no-repeat;
                    background-position: 1.75em; 
                }

				.custom-start-range-${index}:not(.mbsc-calendar-day-empty)  .mbsc-calendar-cell-text {
                    background-color: ${hexColor};
				}`;
				const redDotForStartStyle = `
				.custom-start-range-${index}:not(.mbsc-calendar-day-empty) .mbsc-calendar-cell-inner::after {
					content: '';
					width: 8px; /* Adjust the size of the dot as needed */
					height: 8px;
					background: radial-gradient(circle, red 0%, pink 100%); /* Gradient for color fade */
					border-radius: 15%; 
					position: absolute;
					top: 2px; /* Adjust the position as needed */
					right: 4px; /* Adjust the position as needed */
					box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
				}`;

				const endStyle = `
                .custom-end-range-${index}:not(.mbsc-calendar-day-empty) {
                    background: linear-gradient(to bottom, transparent 0.30em, ${hexColor} 0.30em 2em, transparent 2em ) no-repeat;
                    background-position: -1.75em;
				}

				.custom-end-range-${index}:not(.mbsc-calendar-day-empty)  .mbsc-calendar-cell-text {
                    background-color: ${hexColor};
				}
				`;
				const redDotForEndStyle = `
				.custom-end-range-${index}:not(.mbsc-calendar-day-empty) .mbsc-calendar-cell-inner::after {
					content: '';
					width: 8px; /* Adjust the size of the dot as needed */
					height: 8px;
					background: radial-gradient(circle, red 0%, pink 100%); /* Gradient for color fade */
					border-radius: 15%; 
					position: absolute;
					top: 2px; /* Adjust the position as needed */
					right: 4px; /* Adjust the position as needed */
					box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
				}`;

				const middleStyle = `
                .custom-range-${index}:not(.mbsc-calendar-day-empty) {
                    background: linear-gradient(to bottom, transparent 0.30em, ${hexColor} 0.30em 2em, transparent 2em ) no-repeat;
                }
				`;
				const redDotForMiddleStyle = `
				.custom-range-${index}:not(.mbsc-calendar-day-empty) .mbsc-calendar-cell-inner::after {
					content: '';
					width: 8px; /* Adjust the size of the dot as needed */
					height: 8px;
					background: radial-gradient(circle, red 0%, pink 100%); /* Gradient for color fade */
					border-radius: 15%;
					position: absolute;
					top: 2px; /* Adjust the position as needed */
					right: 4px; /* Adjust the position as needed */
					box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
				}`;

				const soloStyle = `
				.custom-solo-range-${index}:not(.mbsc-calendar-day-empty)  .mbsc-calendar-cell-text {
					background-color: ${hexColor};
				}`;
				const redDotForSoloStyle = `
				.custom-solo-range-${index}:not(.mbsc-calendar-day-empty) .mbsc-calendar-cell-inner::after {
					content: '';
					width: 8px; /* Adjust the size of the dot as needed */
					height: 8px;
					background: radial-gradient(circle, red 0%, pink 100%); /* Gradient for color fade */
					border-radius: 15%;
					position: absolute;
					top: 2px; /* Adjust the position as needed */
					right: 4px; /* Adjust the position as needed */
					box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
				}`;

				const halfDayStyle = `
				.custom-halfDay-range-${index}:not(.mbsc-calendar-day-empty)  .mbsc-calendar-cell-text {
					background: linear-gradient(to right top, ${hexColor} 50%, transparent 50%) no-repeat;
				}`;
				const redDotForHalfDayStyle = `
				.custom-halfDay-range-${index}:not(.mbsc-calendar-day-empty) .mbsc-calendar-cell-inner::after {
					content: '';
					width: 8px; /* Adjust the size of the dot as needed */
					height: 8px;
					background: radial-gradient(circle, red 0%, pink 100%); /* Gradient for color fade */
					border-radius: 15%; 
					position: absolute;
					top: 2px; /* Adjust the position as needed */
					right: 4px; /* Adjust the position as needed */
					box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
				}`;

				return timeoffRequest.cellCssClass === 'start-range'
					? timeoffRequest.createOnPrevYear
						? startStyle + redDotForStartStyle
						: startStyle
					: timeoffRequest.cellCssClass === 'end-range'
					? timeoffRequest.createOnPrevYear
						? endStyle + redDotForEndStyle
						: endStyle
					: timeoffRequest.cellCssClass === 'solo-range'
					? timeoffRequest.createOnPrevYear
						? soloStyle + redDotForSoloStyle
						: soloStyle
					: timeoffRequest.cellCssClass === 'halfDay-range'
					? timeoffRequest.createOnPrevYear
						? halfDayStyle + redDotForHalfDayStyle
						: halfDayStyle
					: timeoffRequest.createOnPrevYear
					? middleStyle + redDotForMiddleStyle
					: middleStyle;
			})
			.join(' ');
		updatedStyleTag.innerHTML = styles;
	}, [timeoffRequests]);

	// Handle change event for Datepicker
	const onChange = useCallback(
		(event: { value: (string | number | Date)[] }) => {
			// If selected date is a Timeoff Request does nothing
			if (!dateIsTimeoffRequest(new Date(event.value[0]), timeoffRequests)) {
				if (event.value[1] !== null) {
					// Create range just After the Second Date been selected
					const startDate = new Date(event.value[0]);
					const endDate = new Date(event.value[1]);
					endDate.setDate(endDate.getDate() - 1);
					const newRange = {
						start: startDate,
						end: endDate
					};

					if (isOverlap(newRange, timeoffRequests)) {
						// Clears date picker value (blue selection)
						if (datePickerRef.current) {
							datePickerRef.current.setVal(undefined);
							datePickerRef.current.setActiveDate(undefined);
						}
						addNotification('info', 'The selected date range overlaps with an existing Time Off Request!');
						return;
					}

					setTimeOffRequestStartDate(startDate);
					setTimeOffRequestEndDate(endDate);
					setTimeOffRequestModalOpen(true);
				}
			} else {
				if (datePickerRef.current) {
					datePickerRef.current.setVal(undefined);
					datePickerRef.current.setActiveDate(undefined);
				}
			}
		},
		[timeoffRequests]
	);

	//Actions of header
	const headerActions = [
		{
			id: 'pdf',
			label: 'Export PDF',
			onClick: () => {
				exportPDFTimeOff({ username: loggedUser.username });
				handleCloseHeaderAction();
			}
		},
		{
			id: 'iCal',
			label: 'Export iCal Vacations Only',
			onClick: () => {
				exportICALTimeOff({ username: loggedUser.username, withHolidays: false });
				handleCloseHeaderAction();
			}
		},
		{
			id: 'iCalVacations',
			label: 'Export iCal Vacations/Holidays',
			onClick: () => {
				exportICALTimeOff({ username: loggedUser.username, withHolidays: true });
				handleCloseHeaderAction();
			}
		}
	];

	// Close header action menu
	const handleCloseHeaderAction = () => {
		setAnchorElHeaderAction(null);
	};

	// Open header action menu
	const handleClickHeaderAction = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElHeaderAction(event.currentTarget);
	};

	// Handle hover in on Calendar cell
	const onCellHoverIn = (args: any, inst: any) => {
		inst.props.colors.forEach(element => {
			if (args.target.classList.contains(element.cellCssClass)) {
				if (element.accountTimeOffType && element.approvalStatus) {
					setIsOpenToolTip(true);
					setAnchor(args.target);
					setStatusToolTip(element.approvalStatus);
					if (element.createOnPrevYear) {
						setTimeoffRequest(
							<>
								{element.accountTimeOffType}
								<p style={{ color: 'red', fontSize: '10px' }}>
									Created on: {convertDateToDDMMYYYY(element.createOn)}
								</p>
								<p style={{ marginTop: '-10px', color: 'red', fontSize: '10px' }}>
									This time off was scheduled in the previous year
								</p>
							</>
						);
					} else {
						setTimeoffRequest(element.accountTimeOffType);
					}
					setTimeoffRequestColor(element.backgroundAux);
				}
			}
		});
	};

	// Handle hover out on Calendar cell
	const onCellHoverOut = (args: any, inst: any) => {
		setIsOpenToolTip(false);
		setAnchor(null);
		setStatusToolTip('');
		setTimeoffRequest('');
	};

	// Render header for Datepicker
	const calendarHeader = () => (
		<Grid container direction="column" justifyContent="space-between" alignContent="space-between">
			<Grid container direction="row" justifyContent="space-between">
				<Grid item xs={7}>
					<Button
						id={'toolbarFiltersID'}
						className={classes.newEntryButton}
						variant="outlined"
						onClick={() => {
							setTimeOffRequestModalOpen(true);
						}}
						// disabled={isDifferentUserOpened}
					>
						+ New Entry
					</Button>
				</Grid>
				<Divider orientation="vertical" variant="middle" flexItem />
				<Grid item xs={1.5} id={'toolbarGraphSwitchID'}>
					<Toggler
						disabled={false}
						size="small"
						orientation="horizontal"
						defaultValue={currentToggle}
						additionalOnChange={e => {
							setCurrentToggle(e);
							if (e == 'calendar') {
								let date = new Date();
								date.setFullYear(currentYear);
								setSelectedDate(date);
								setSelectedDateAux(date);
							}
							// if (e == 'weekly') setNeedsCalendarToUpdateHours(false);
						}}
						options={[
							{
								id: 0,
								value: 'calendar',
								icon: <CalendarToday />
							},
							{
								id: 1,
								value: 'table',
								icon: <TableChartOutlined />
							}
						]}
					/>
				</Grid>
				<Divider orientation="vertical" variant="middle" flexItem />
				<Grid item xs={1} className={classes.displayFlex}>
					<Button
						id={'toolbarFiltersID'}
						className={classes.filterButton}
						onClick={e => {
							setOpenFiltersModal(true);
						}}
						variant="outlined">
						Filters
					</Button>
				</Grid>
				<Divider orientation="vertical" variant="middle" flexItem />
				<Grid item xs={1} id={'toolbarExtraActionsID'}>
					<IconButton
						className={classes.headerActions}
						onClick={handleClickHeaderAction}
						id="header-Actions-button">
						<MoreVert />
					</IconButton>
					<Menu
						id="header-Actions-menu"
						aria-labelledby="header-Actions-button"
						open={Boolean(anchorElHeaderAction)}
						anchorEl={anchorElHeaderAction}
						onClose={handleCloseHeaderAction}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}>
						{headerActions.map(e => (
							<MenuItem key={`headerAction_${e.id}`} onClick={e.onClick} disabled={e.disabled}>
								{e.label}
							</MenuItem>
						))}
					</Menu>
				</Grid>
			</Grid>
			<Grid container direction="row" alignItems="center" className={classes.navBar}>
				<Grid item xs={9} />
				<Grid item container direction="row" xs={3} justifyContent="flex-end" alignItems="center">
					<Grid item className={classes.calendarNavs}>
						<IconButton
							color="primary"
							style={{ marginRight: '20px', height: '55px', width: '55px' }}
							onClick={e => {
								setCurrentYear(currentYear - 1);
								const newDate = startOfMonth(new Date());
								newDate.setFullYear(currentYear - 1);
								setSelectedDate(newDate);
								setSelectedDateAux(newDate);
							}}>
							<ArrowLeft />
						</IconButton>
					</Grid>
					<Grid item>
						<CalendarNav />
						{currentToggle == 'table' && (
							<label
								style={{
									fontSize: '23px',
									color: '#3F80EF',
									fontWeight: 'bold'
								}}>
								{currentYear}
							</label>
						)}
					</Grid>
					<Grid item className={classes.calendarNavs}>
						<IconButton
							color="primary"
							style={{ marginLeft: '20px', height: '60px', width: '60px' }}
							onClick={e => {
								setCurrentYear(currentYear + 1);
								const newDate = startOfMonth(new Date());
								newDate.setFullYear(currentYear + 1);
								setSelectedDate(newDate);
								setSelectedDateAux(newDate);
							}}>
							<ArrowRight />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
	//Prepares the data to the go to cards
	const prepareGoToCardInfo = () => {
		const approvalStatusOrder = {
			Rejected: 0,
			Saved: 1,
			Submitted: 2,
			Approved: 3
		};

		const accountEmployeeTimeoffRequests = timeoffData?.accountEmployeeTimeoffRequests?.filter(
			e =>
				(e.rejected !== true || e.approved == false || e.requestSubmitDate == '' || !e.requestSubmitDate) &&
				new Date(e.startDate).getFullYear() == selectedDateAux?.getFullYear()
		);

		// Sort the requests by approvalStatus and startDate
		const sortedRequests = accountEmployeeTimeoffRequests?.sort((a, b) => {
			if (approvalStatusOrder[a.approvalStatus] !== approvalStatusOrder[b.approvalStatus]) {
				return approvalStatusOrder[a.approvalStatus] - approvalStatusOrder[b.approvalStatus];
			}
			return new Date(a.startDate) - new Date(b.startDate);
		});

		let cardInfos: ITimeoffRequest[][] = [];

		sortedRequests?.forEach((element, index) => {
			let title = '';
			if (element.approvalStatus == 'Saved') {
				title = 'Not Submitted';
			} else {
				title = element.approvalStatus;
			}

			cardInfos.push([
				{
					id: index,
					title: title,
					description: element.hoursOff == 4 ? '0,5 Days' : element.dayOff + ' Days',
					period: '',
					accountTimeOffTypeId: element.accountTimeOffTypeId,
					startDate: element.startDate,
					endDate: element.endDate,
					dayOff: element.dayOff,
					hoursOff: element.hoursOff,
					timeOffDescription: element.description,
					accountEmployeeTimeOffRequestId: element.accountEmployeeTimeOffRequestId,
					accountEmployeeId: element.accountEmployeeId,
					accountTimeOffType: element.accountTimeOffType,
					createdOn: element.createdOn,
					approvedOn: element.approvedOn,
					modifiedOn: element.modifiedOn,
					requestSubmitDate: element.requestSubmitDate
				}
			]);
		});

		return cardInfos;
	};

	//GoToCards with hardcoded information (will be later added external information)
	const missingTimesheetsCards = prepareGoToCardInfo().map((cardInfo, index) => {
		let extraButtons: any = [];
		if (cardInfo[0].title == 'Submitted') {
			extraButtons = [
				{
					name: 'Unsubmit',
					onClick: () => {
						setTimeoffAction('Unsubmit');
						setSelectedTimeOffRequest(cardInfo[0]);
						setTimeoffActionModal(true);
					}
				}
			];
		} else if (cardInfo[0].title == 'Rejected') {
			extraButtons = [
				{
					name: 'Delete',
					onClick: () => {
						setTimeoffAction('Delete');
						setSelectedTimeOffRequest(cardInfo[0]);
						setTimeoffActionModal(true);
					},
					variant: 'outlined'
				}
			];
		} else if (cardInfo[0].title == 'Not Submitted') {
			extraButtons = [
				{
					name: 'Delete',
					onClick: () => {
						setTimeoffAction('Delete');
						setSelectedTimeOffRequest(cardInfo[0]);
						setTimeoffActionModal(true);
					},
					variant: 'outlined'
				},
				{
					name: 'Edit',
					onClick: () => {
						setTimeOffRequestStartDate(new Date(cardInfo[0].startDate));
						setTimeOffRequestEndDate(new Date(cardInfo[0].endDate));
						setSelectedTimeOffRequest(cardInfo[0]);
						setEditingTimeoffRequest(true);
						setTimeOffRequestModalOpen(true);
					}
				},
				{
					name: 'Submit',
					onClick: () => {
						setTimeoffAction('Submit');
						setSelectedTimeOffRequest(cardInfo[0]);
						setTimeoffActionModal(true);
					}
				}
			];
		} else if (cardInfo[0].title == 'Approved') {
			extraButtons = [
				{
					name: 'Request Change',
					onClick: () => {
						window.open(
							'https://apps.xpand-it.com/jirasd/servicedesk/customer/portal/3/create/24',
							'_blank'
						);
					}
				}
			];
		}

		return (
			<div key={cardInfo.id} id="idCard" style={{ paddingTop: '10px', paddingLeft: '10px' }}>
				{cardInfo[0].startDate === cardInfo[0].endDate ? (
					<>
						Day <strong>{convertDateToDDMMYY(cardInfo[0].startDate)}</strong>
					</>
				) : (
					<>
						From <strong>{convertDateToDDMMYY(cardInfo[0].startDate)}</strong> to{' '}
						<strong>{convertDateToDDMMYY(cardInfo[0].endDate)}</strong>
					</>
				)}
				<GoToCard
					id={index}
					info={cardInfo}
					onClick={e => {
						getTimeoffRequestApprovalDetailsData({
							accountEmployeeTimeOffRequestId: cardInfo[0].accountEmployeeTimeOffRequestId
						});
						setSelectedTimeOffRequest(cardInfo[0]);
						setOpenViewDetails(true);
					}}
					actionButtons={extraButtons}
					hasBorderBottom={false}
					borderColor={
						cardInfo[0].title === 'Rejected'
							? '#D1345E'
							: cardInfo[0].title === 'Submitted'
							? '#2BAAFF'
							: cardInfo[0].title === 'Approved'
							? '#21BA45'
							: '#FAC15F'
					}
				/>
				<Divider style={{ marginTop: '10px' }} />
			</div>
		);
	});

	// Time off  Actions
	const timeoffActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setTimeoffActionModal(false);
				setTimeoffAction('');
				setSelectedTimeOffRequest(null);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setIsAfterCardAction(true);
				if (timeoffAction == 'Delete') {
					deleteTimeoff({
						accountEmployeeId: selectedTimeOffRequest?.accountEmployeeId,
						timeOffRequestId: selectedTimeOffRequest?.accountEmployeeTimeOffRequestId
					});
				} else if (timeoffAction == 'Submit') {
					submitTimeoff({
						accountEmployeeId: selectedTimeOffRequest?.accountEmployeeId,
						timeOffRequestsId: [{ id: selectedTimeOffRequest?.accountEmployeeTimeOffRequestId }]
					});
				} else if (timeoffAction == 'Unsubmit') {
					unsubmitTimeoff({
						accountEmployeeId: selectedTimeOffRequest?.accountEmployeeId,
						timeOffRequestId: selectedTimeOffRequest?.accountEmployeeTimeOffRequestId
					});
				}
				setTimeoffActionModal(false);
				setTimeoffAction('');
				setSelectedTimeOffRequest(null);
			}
		}
	];

	const isLoading = timeoffData === null || loading || undefined;
	if (isLoading) return <LoadingOverlay />;

	// MyTimeoff component return
	return (
		<div ref={contentRefs}>
			{isLoading && <LoadingOverlay />}
			{isDifferentUserOpened && (
				<BackPage label="Return to my Time Off" path="/admin/time-tracking/time-off" action={refreshPage} />
			)}
			<Grid container direction="row" justifyContent="space-around">
				{currentToggle === 'calendar' && (
					<Grid item xs={isRightBarOpen ? (isTablet ? (isTabletBetween ? 9.5 : 9) : 10) : 11}>
						<Datepicker
							onInit={(event, inst) => {
								datePickerRef.current = inst; // Store the instance in the ref
								setSelectedDate(undefined);
							}}
							onPageChange={(event, inst) => {
								setCurrentYear(event.firstDay.getFullYear());
								setSelectedDate(event.firstDay);
								setSelectedDateAux(event.firstDay);
								setSelectedDate(undefined);
								inst.setVal(undefined);
								inst.setActiveDate(undefined);
							}}
							value={selectedDate ? selectedDate : undefined}
							controls={['calendar']}
							select="range"
							display="inline"
							dataTimezone="utc"
							displayTimezone="Europe/London"
							timezonePlugin={momentTimezone}
							calendarType={'year'} // or 'month'
							calendarSize={1} // if calendarType='month' can be more than 1
							renderCalendarHeader={calendarHeader}
							dayNamesMin={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
							firstDay={1}
							showRangeLabels={false}
							onChange={onChange}
							colors={timeoffRequests.map((timeoffRequest, index) => ({
								...timeoffRequest,
								cellCssClass: `custom-${timeoffRequest.cellCssClass}-${index}`
							}))}
							labels={statusUnderlines}
							onCellHoverIn={onCellHoverIn}
							onCellHoverOut={onCellHoverOut}
							onCellClick={args => {
								// Scrolls to a card if selected date is a Timeoff Request
								if (dateIsTimeoffRequest(args?.date, timeoffRequests)) {
									setIsRightBarOpen(true);
									//! HTML DOM paths that can change if the Right Sidebar component is modified
									const cards =
										cardRefs?.current?.children[1].children[0].children[0].children[0].children[1]
											.children;
									const scrollableElement = cardRefs?.current?.children[1].children[0].children[0];
									const pageContentElement = contentRefs?.current?.parentNode?.parentNode;
									scrollToCard(args?.date, cards, scrollableElement, pageContentElement);
								}
							}}
						/>
						{timeoffData?.managersNamebyEmployeeId.length > 0 && (
							<Box display="flex" justifyContent="center" alignItems="center">
								<Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold' }}>
									The person that approves your Time Off is :{' '}
									{timeoffData?.managersNamebyEmployeeId[0]}
								</Typography>
							</Box>
						)}
						<Grid container direction="row" className={classes.footerStatus} alignItems="center">
							<Grid item xs={8} id={'footerLegendID'}>
								<Subtitles subtitles={subtitles} title="Status Legend:" circleFormat={true}></Subtitles>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									component="div"
									style={{ fontWeight: 'bold', marginTop: '5px' }}>
									For the "Scheduled Absences Detail Procedure" please check the following{' '}
									<a
										href="https://apps.xpand-it.com/confluence/pages/viewpage.action?pageId=226213065#absences_page"
										target="_blank">
										link
									</a>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
				{currentToggle === 'table' && (
					<Grid item xs={12}>
						<Grid container direction="column">
							<Grid item xs={2}>
								{calendarHeader()}
							</Grid>
							<Grid item style={{ transform: 'scale(0.95)', marginTop: '-100px' }}>
								<Table densePadding tableData={tableData} columns={tableColumns} />
							</Grid>
						</Grid>
					</Grid>
				)}
				{currentToggle === 'calendar' && (
					<Grid item xs={isRightBarOpen ? (isTablet ? (isTabletBetween ? 2 : 2.5) : 1.5) : 1} ref={cardRefs}>
						{/* Note: Changes in the dom structure of this component may affect redirect functionality!!! */}
						<RightSidebar
							onClick={openCloseRightSideBar}
							isOpen={isRightBarOpen}
							isTimeOff={true}
							showDividers={false}
							headerComponent={
								<>
									{' '}
									{/* Adjust the padding as necessary */}
									<div
										style={{
											position: isRightBarOpen ? 'fixed' : 'relative',
											zIndex: 1000,
											backgroundColor: 'white',
											width: '18%',
											height: '50px',
											marginTop: '-10px', // Set a fixed height
											justifyContent: 'flex-start',
											alignItems: 'center'
										}}>
										<Typography style={{ fontWeight: 'bold' }} variant="h5" component="span">
											Scheduled
										</Typography>{' '}
										<Typography variant="h5" component="span">
											Time Off
										</Typography>
										<Divider style={{ marginTop: '20px', marginBottom: '10px' }} />{' '}
									</div>
									<div style={{ paddingTop: '40px' }}>{missingTimesheetsCards}</div>
								</>
							}
						/>
					</Grid>
				)}
			</Grid>

			<Popup
				display="anchored"
				isOpen={isOpenToolTip}
				anchor={anchor}
				touchUi={false}
				showOverlay={false}
				contentPadding={false}
				closeOnOverlayClick={false}
				width={200}
				height={timeoffRequest.length && timeoffRequest.length < 40 ? 85 : 130}
				cssClass="md-tooltip">
				<Grid container direction="column" spacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
					<Grid item className="md-tooltip-header">
						<Grid container direction="row" spacing={2}>
							<Grid item>
								<Badge
									variant="dot"
									className={classes.selectedBadge}
									color={
										statusToolTip == 'Submitted'
											? 'primary'
											: statusToolTip == 'Approved'
											? 'success'
											: statusToolTip == 'Rejected'
											? 'warning'
											: statusToolTip == 'Holiday'
											? 'info'
											: 'warningOrange'
									}
								/>
							</Grid>
							<Grid item>
								<div style={{ fontSize: '10px' }}>{statusToolTip}</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						className="md-tooltip-title"
						style={{
							color: timeoffRequestColor
						}}>
						{timeoffRequest}
					</Grid>
				</Grid>
			</Popup>
			<TimeOffRequestModal
				saveTimeoff={saveTimeoff}
				isTimeOffRequestModalOpen={isTimeOffRequestModalOpen}
				setTimeOffRequestModalOpen={setTimeOffRequestModalOpen}
				isEditingTimeoffRequest={isEditingTimeoffRequest}
				setEditingTimeoffRequest={setEditingTimeoffRequest}
				timeOffRequestStartDate={timeOffRequestStartDate}
				setTimeOffRequestStartDate={setTimeOffRequestStartDate}
				timeOffRequestEndDate={timeOffRequestEndDate}
				setTimeOffRequestEndDate={setTimeOffRequestEndDate}
				selectedTimeOffRequest={selectedTimeOffRequest}
				setSelectedTimeOffRequest={setSelectedTimeOffRequest}
				setIsAfterSave={setIsAfterSave}
				setDateAfterSave={setDateAfterSave}
				statusCredits={timeoffData.statusCreditsCurrentYear}
				timeoffRequests={timeoffRequests}
				setTimeoffRequests={setTimeoffRequests}
				setStatusUnderlines={setStatusUnderlines}
				statusUnderlines={statusUnderlines}
				onCellHoverIn={onCellHoverIn}
				onCellHoverOut={onCellHoverOut}
				holidays={timeoffData.accountEmployeeHolidayTypes}
				hrAbsenceProject={timeoffData.hrAbsenceProject}
				mainDatePickerRef={datePickerRef}
			/>
			{/* Dialog for time off delete/ submit / unsubmit actions */}
			<Dialog
				modal={{
					open: Boolean(timeoffActionModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setTimeoffActionModal(false);
					},
					content: (
						<Typography gutterBottom>
							Are you sure you want to {timeoffAction.toLocaleLowerCase()} this time off request?
						</Typography>
					)
				}}
				title={timeoffAction + ' Time Off Request'}
				actions={timeoffActions}
				scroll="body"
			/>

			<Dialog
				modal={{
					open: Boolean(openFiltersModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenFiltersModal(false);
					},
					content: (
						<LibSelect
							name="newSheetEmployee"
							label="EMPLOYEE NAME"
							value={filterEmployee}
							hasSearchOption={true}
							options={timeoffData.Employees.map(e => ({
								id: e.username.toLocaleLowerCase(),
								label: e.username + ' - ' + e.firstName + ' ' + e.lastName
							})).sort((a, b) => {
								if (a.label < b.label) return -1;
								if (a.label > b.label) return 1;
								return 0;
							})}
							onChange={e => {
								setFilterEmployee(e);
							}}
						/>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setOpenFiltersModal(false)
					},
					{
						id: 'changeUser',
						label: 'Change',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							getTimeoffData({ username: filterEmployee });
							setOpenFiltersModal(false);
							if (loggedUser.username != filterEmployee) {
								setDifferentUserOpened(true);
							}
						}
					}
				]}
				title="Change Time Off User"
				scroll="body"
			/>
			{/*Dialog for view timeoff request details*/}
			<ViewDetailsModal
				timeOffRequestApprovalDetailData={timeOffRequestApprovalDetailData}
				selectedTimeOffRequest={selectedTimeOffRequest}
				setSelectedTimeOffRequest={setSelectedTimeOffRequest}
				setOpenViewDetails={setOpenViewDetails}
				openViewDetails={openViewDetails}
			/>
		</div>
	);
};

export default withLayout(MyTimeoff);
