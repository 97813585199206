// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		titlesGrid: {
			height: '40px',
			color: 'white',
			marginBottom: '20px',
			backgroundColor: 'rgba(0, 0, 255, 0.5)',
			borderRadius: '5px',
			boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
		},
		titles: {
			marginTop: '10px',
			marginLeft: '30px',
			fontWeight: 'bold'
		},
		tabheader: {
			marginBottom: '50px'
		}
	})
);

export { useStyles };
