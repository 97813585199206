//* EXTERNAL LIBS
import React, { useEffect, FC, useMemo, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Button, Grid, IconButton, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, CollapseTable, TabHeader, Tab, Dialog, Table } from 'xpand-ui/core';
import { Checkbox } from 'xpand-ui/forms';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { ExpenseProps } from '.';
import { decimalToHours } from '../../../PersonalInfo/MyTimeTracking/MyTimesheets/utils';

import { useDispatch, useSelector } from 'react-redux';
import {
	getApprovalsExpense,
	downloadAttachmentApprovalsExpenses,
	postApprovalsExpenses
} from 'store/personalInfo/thunks';
import { convertMinutesToTime, tabsExpenses } from '../utils';
import { IUserToken } from 'typings/store/generalTypes';
import { ITableColumn } from 'typings/store/ComponentLib';
import { format } from 'date-fns';
import Filters from '../Components/Filters/Filters';
import { setRemoveUpdateExpenses } from 'store/personalInfo/actions';
import { Download } from '@mui/icons-material';
import { addNotification } from 'lib/utils/notifications';

//* COMPONENT INTERFACES
interface IExpense extends ExpenseProps {
	isAdmin: string;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
	userProfile: IUserProfile;
}
interface IConfirmModal {
	type: string;
	title: string;
	message?: string | ReactNode;
	payload?: any;
}
interface IUserPermissions {
	id: number;
	displayName: string;
}

//* COMPONENT
const Expense: FC<IExpense> = ({ loggedUser }) => {
	const classes = useStyles();
	const approvalsExpenses: any = useSelector(state => state.personalInfo.approvalsExpenses);
	const updateExpenses: any = useSelector(state => state.personalInfo.updateExpenses);
	const loading = useSelector(state => state.personalInfo.loading);
	const [approvalsExpensesFinal, setApprovalsExpensesFinal] = useState<any>();
	const [firstLoading, setFirstLoading] = useState<any>(true);
	const [auxApprovalsExpenses, setAuxApprovalsExpenses] = useState<any>();
	//Approval Details
	const [openDetailsModal, setOpenDetailsModal] = useState(false);
	const [openExpenseDownload, setOpenExpenseDownload] = useState(false);
	const [entryIdToDetails, setEntryIdToDetails] = useState<any>();
	const [entryAttachments, setEntryAttachments] = useState(false);

	const dispatch = useDispatch();
	const [tabSelected, setTabSelected] = useState(0);
	const [tabsExpenseFinal, setTabsExpenseFinal] = useState(tabsExpenses);
	//Approvals -My Projects Time Entry Approvals
	const [groupedDataApprovalEntriesForProjectManager, setGroupedDataApprovalEntriesForProjectManager] =
		useState<any>();
	//Approvals - My Team Time Entry Approvals
	const [groupedDataApprovalEntriesForTeamLeadSummarized, setGroupedDataApprovalEntriesForTeamLeadSummarized] =
		useState<any>();
	//Approvals - Specific External User Time Entry Approvals
	const [
		groupedDataApprovalEntriesForSpecificExternalUserSummarized,
		setGroupedDataApprovalEntriesForSpecificExternalUserSummarized
	] = useState<any>();
	//Approvals - Specific Employee Time Entry Approvals
	const [
		groupedDataApprovalEntriesForSpecificEmployeeSummarized,
		setGroupedDataApprovalEntriesForSpecificEmployeeSummarized
	] = useState<any>();
	//Approvals - Employee Manager Expense Entry Approvals
	const [
		groupedDataApprovalEntriesForEmployeeManagerSummarized,
		setGroupedDataApprovalEntriesForEmployeeManagerSummarized
	] = useState<any>();

	const rowsHeaderApprovalEntriesForProjectManager = useMemo(() => ['Description', 'Date', 'Amount'], []);

	//! ------------------------ TIMESHEET APPROVALS --------------------------------------//
	//Gets all timesheet approvals of the logged user
	useEffect(() => {
		dispatch(
			getApprovalsExpense({
				isFirstLoad: false,
				username: loggedUser.username,
				includeDateRange: false
			})
		);
	}, []);

	//Data os copied to a variable to be manipulated
	useEffect(() => {
		setFirstLoading(true);
		setAuxApprovalsExpenses(approvalsExpenses);
	}, [approvalsExpenses]);

	useEffect(() => {
		if (firstLoading && approvalsExpenses) {
			setApprovalsExpensesFinal(auxApprovalsExpenses);
			setFirstLoading(false);
		}
		if (updateExpenses && !firstLoading && auxApprovalsExpenses) {
			setApprovalsExpensesFinal(auxApprovalsExpenses);
			setFirstLoading(false);
			dispatch(setRemoveUpdateExpenses());
		}
	}, [auxApprovalsExpenses]);

	function deepEqual(obj1, obj2) {
		if (obj1 === obj2) return true; // Same reference or primitive value

		if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
			return false; // Not objects or one is null
		}

		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) return false; // Different number of keys

		for (let key of keys1) {
			if (!keys2.includes(key)) return false; // Key missing in the second object
			if (!deepEqual(obj1[key], obj2[key])) return false; // Recursively check values
		}

		return true;
	}

	//Data os copied to a variable to be manipulated
	useEffect(() => {
		if (!deepEqual(auxApprovalsExpenses, approvalsExpensesFinal) && auxApprovalsExpenses) {
			setAuxApprovalsExpenses(
				Object.fromEntries(
					// Use Object.fromEntries to transform the entries back to an object
					Object.entries(auxApprovalsExpenses).map(([key, array]) => {
						// Check if the value is an array
						if (Array.isArray(array)) {
							// Return the updated array after filtering and mapping
							return [
								key, // Keep the original key
								array.map(item => {
									const updatedEntry = approvalsExpensesFinal[key].find(
										entry =>
											entry.accountEmployeeExpenseSheetId === item.accountEmployeeExpenseSheetId
									);

									// If an updated entry is found and there are changes, update the original entry
									if (updatedEntry) {
										// Compare and update only the fields that have changed
										const hasChanges = Object.keys(updatedEntry).some(
											key2 => updatedEntry[key2] !== item[key2]
										);

										if (hasChanges) {
											// Merge the updated entry into the original entry (only changed fields)
											return { ...item, ...updatedEntry };
										}
									}

									// If no updates are found, return the original entry unchanged
									return item;
								})
							];
						}
						// If the value is not an array, return it unchanged (or handle it as needed)
						return [key, array];
					})
				)
			);
		}
	}, [approvalsExpensesFinal]);

	//Fill every table with the respective information
	useEffect(() => {
		//Approvals -My Projects Expense Entry Approvals
		if (approvalsExpensesFinal?.approvalEntriesForProjectManagerSummarized) {
			setGroupedDataApprovalEntriesForProjectManager(
				Object.values(
					approvalsExpensesFinal?.approvalEntriesForProjectManagerSummarized?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						acc[item.employeeName].push({
							key: 'approvalEntriesForProjectManagerSummarized',
							employeeName: item.employeeName,
							id: item.accountEmployeeExpenseSheetId,
							status: item.status,
							comment: item.comment || null,
							description: item.description,
							date: format(new Date(item.expenseSheetDate), 'dd/MM/yyyy'),
							amount: new Intl.NumberFormat('pt-PT', {
								style: 'currency',
								currency: item.baseCurrencyCode
							}).format(item.amount)
						});
						return acc;
					}, {})
				)
			);
			//Approvals - My Team Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForTeamLeadSummarized) {
				setGroupedDataApprovalEntriesForTeamLeadSummarized(
					Object.values(
						approvalsExpensesFinal?.approvalEntriesForTeamLeadSummarized?.reduce((acc, item) => {
							if (!acc[item.employeeName]) {
								acc[item.employeeName] = [];
							}

							acc[item.employeeName].push({
								key: 'approvalEntriesForTeamLeadSummarized',
								employeeName: item.employeeName,
								id: item.accountEmployeeExpenseSheetId,
								status: item.status,
								comment: item.comment || null,
								description: item.description,
								date: format(new Date(item.expenseSheetDate), 'dd/MM/yyyy'),
								amount: new Intl.NumberFormat('pt-PT', {
									style: 'currency',
									currency: item.baseCurrencyCode
								}).format(item.amount)
							});
							return acc;
						}, {})
					)
				);
			}
			//Approvals - Specific External User Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForSpecificExternalUserSummarized) {
				setGroupedDataApprovalEntriesForSpecificExternalUserSummarized(
					Object.values(
						approvalsExpensesFinal?.approvalEntriesForSpecificExternalUserSummarized?.reduce(
							(acc, item) => {
								if (!acc[item.employeeName]) {
									acc[item.employeeName] = [];
								}

								acc[item.employeeName].push({
									key: 'approvalEntriesForSpecificExternalUserSummarized',
									employeeName: item.employeeName,
									id: item.accountEmployeeExpenseSheetId,
									status: item.status,
									comment: item.comment || null,
									description: item.description,
									date: format(new Date(item.expenseSheetDate), 'dd/MM/yyyy'),
									amount: new Intl.NumberFormat('pt-PT', {
										style: 'currency',
										currency: item.baseCurrencyCode
									}).format(item.amount)
								});
								return acc;
							},
							{}
						)
					)
				);
			}
			//Approvals - Specific Employee Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForSpecificEmployeeSummarized) {
				setGroupedDataApprovalEntriesForSpecificEmployeeSummarized(
					Object.values(
						approvalsExpensesFinal?.approvalEntriesForSpecificEmployeeSummarized?.reduce((acc, item) => {
							if (!acc[item.employeeName]) {
								acc[item.employeeName] = [];
							}

							acc[item.employeeName].push({
								key: 'approvalEntriesForSpecificEmployeeSummarized',
								employeeName: item.employeeName,
								id: item.accountEmployeeExpenseSheetId,
								status: item.status,
								comment: item.comment || null,
								description: item.description,
								date: format(new Date(item.expenseSheetDate), 'dd/MM/yyyy'),
								amount: new Intl.NumberFormat('pt-PT', {
									style: 'currency',
									currency: item.baseCurrencyCode
								}).format(item.amount)
							});
							return acc;
						}, {})
					)
				);
			}
			//Approvals - Specific Employee Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForEmployeeManagerSummarized) {
				setGroupedDataApprovalEntriesForEmployeeManagerSummarized(
					Object.values(
						approvalsExpensesFinal?.approvalEntriesForEmployeeManagerSummarized?.reduce((acc, item) => {
							if (!acc[item.employeeName]) {
								acc[item.employeeName] = [];
							}

							acc[item.employeeName].push({
								key: 'approvalEntriesForEmployeeManagerSummarized',
								employeeName: item.employeeName,
								id: item.accountEmployeeExpenseSheetId,
								status: item.status,
								comment: item.comment || null,
								description: item.description,
								date: format(new Date(item.expenseSheetDate), 'dd/MM/yyyy'),
								amount: new Intl.NumberFormat('pt-PT', {
									style: 'currency',
									currency: item.baseCurrencyCode
								}).format(item.amount)
							});
							return acc;
						}, {})
					)
				);
			}
		}
	}, [approvalsExpensesFinal]);

	//!------------------------------------------UPDATE APPROVALS-------------------------------------------//

	useEffect(() => {
		if (updateExpenses) {
			dispatch(
				getApprovalsExpense({
					isFirstLoad: false,
					username: loggedUser.username,
					includeDateRange: false
				})
			);
		}
	}, [updateExpenses]);

	const updateExpenseEntryApprovals = () => {
		let formToUpdate: any = {};
		let specificEmployeeGrid: any = [];
		let employeeManagerGrid: any = [];
		let projectManagerGrid: any = [];
		let teamLeadGrid: any = [];
		let specificExternalUserGrid: any = [];
		for (let entries in auxApprovalsExpenses) {
			for (let i = 0; i < auxApprovalsExpenses[entries].length; i++) {
				const e = auxApprovalsExpenses[entries][i];
				if (e.status === 'rejected' && (e.comment === undefined || e.comment === '')) {
					addNotification(
						'danger',
						'One or more entries are rejected without comment. Please insert a comment in all rejected entries.'
					);
					// Stop further execution by returning early
					return;
				}
			}
		}

		for (let entries in auxApprovalsExpenses) {
			auxApprovalsExpenses[entries].map(e => {
				if (e.status === 'approved' || e.status === 'rejected') {
					if (entries === 'approvalEntriesForSpecificEmployeeSummarized') {
						specificEmployeeGrid = [
							...specificEmployeeGrid,
							{
								employeeName: e.employeeName,
								expenseSheetDate: e.expenseSheetDate,
								amount: e.amount,
								accountEmployeeExpenseSheetId: e.accountEmployeeExpenseSheetId,
								baseCurrencyCode: e.baseCurrencyCode,
								approvalPathSequence: 1,
								expenseEntryAccountEmployeeId: e.expenseEntryAccountEmployeeId,
								description: e.description,
								isApproved: e.status === 'approved' ? true : false,
								isRejected: e.status === 'rejected' ? true : false,
								comments: e.comment ? e.comment : ''
							}
						];
					}
					if (entries === 'approvalEntriesForTeamLeadSummarized') {
						teamLeadGrid = [
							...teamLeadGrid,
							{
								employeeName: e.employeeName,
								expenseSheetDate: e.expenseSheetDate,
								amount: e.amount,
								accountEmployeeExpenseSheetId: e.accountEmployeeExpenseSheetId,
								baseCurrencyCode: e.baseCurrencyCode,
								approvalPathSequence: 1,
								expenseEntryAccountEmployeeId: e.expenseEntryAccountEmployeeId,
								description: e.description,
								isApproved: e.status === 'approved' ? true : false,
								isRejected: e.status === 'rejected' ? true : false,
								comments: e.comment ? e.comment : ''
							}
						];
					}
					if (entries === 'approvalEntriesForSpecificExternalUserSummarized') {
						specificExternalUserGrid = [
							...specificExternalUserGrid,
							{
								employeeName: e.employeeName,
								expenseSheetDate: e.expenseSheetDate,
								amount: e.amount,
								accountEmployeeExpenseSheetId: e.accountEmployeeExpenseSheetId,
								baseCurrencyCode: e.baseCurrencyCode,
								approvalPathSequence: 1,
								expenseEntryAccountEmployeeId: e.expenseEntryAccountEmployeeId,
								description: e.description,
								isApproved: e.status === 'approved' ? true : false,
								isRejected: e.status === 'rejected' ? true : false,
								comments: e.comment ? e.comment : ''
							}
						];
					}
					if (entries === 'approvalEntriesForEmployeeManagerSummarized') {
						employeeManagerGrid = [
							...employeeManagerGrid,
							{
								employeeName: e.employeeName,
								expenseSheetDate: e.expenseSheetDate,
								amount: e.amount,
								accountEmployeeExpenseSheetId: e.accountEmployeeExpenseSheetId,
								baseCurrencyCode: e.baseCurrencyCode,
								approvalPathSequence: 1,
								expenseEntryAccountEmployeeId: e.expenseEntryAccountEmployeeId,
								description: e.description,
								isApproved: e.status === 'approved' ? true : false,
								isRejected: e.status === 'rejected' ? true : false,
								comments: e.comment ? e.comment : ''
							}
						];
					}
					if (entries === 'approvalEntriesForProjectManagerSummarized') {
						projectManagerGrid = [
							...projectManagerGrid,
							{
								employeeName: e.employeeName,
								expenseSheetDate: e.expenseSheetDate,
								amount: e.amount,
								accountEmployeeExpenseSheetId: e.accountEmployeeExpenseSheetId,
								baseCurrencyCode: e.baseCurrencyCode,
								approvalPathSequence: 1,
								expenseEntryAccountEmployeeId: e.expenseEntryAccountEmployeeId,
								description: e.description,
								isApproved: e.status === 'approved' ? true : false,
								isRejected: e.status === 'rejected' ? true : false,
								comments: e.comment ? e.comment : ''
							}
						];
					}
				}
			});
		}

		if (
			specificEmployeeGrid.length > 0 ||
			employeeManagerGrid.length > 0 ||
			projectManagerGrid.length > 0 ||
			teamLeadGrid.length > 0 ||
			specificExternalUserGrid.length > 0
		) {
			formToUpdate = {
				approverUsername: loggedUser.username,
				specificEmployeeGrid: specificEmployeeGrid,
				employeeManagerGrid: employeeManagerGrid,
				projectManagerGrid: projectManagerGrid,
				teamLeadGrid: teamLeadGrid,
				specificExternalUserGrid: specificExternalUserGrid
			};
			dispatch(postApprovalsExpenses(formToUpdate));
		}
	};

	const disableUpdateButton = () => {
		for (let entries in auxApprovalsExpenses) {
			for (let e of auxApprovalsExpenses[entries]) {
				if ((e.status === 'approved' || e.status === 'rejected') && !e.isTimeOff) {
					return false;
				} else if ((e.status === 'approved' || e.status === 'rejected') && e.isTimeOff) {
					return false;
				}
			}
		}
		return true;
	};

	//Every entry in the table will be approved, giving the status approved to the entries of the main data
	const approveAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsExpensesFinal).reduce((acc, key) => {
			const allEntries = approvalsExpensesFinal[key].filter(
				entry => entry.employeeName === group[0].employeeName
			);
			const allApproved = allEntries.every(e => e.status == 'approved');
			// Map over the entries and update the status accordingly
			acc[key] = approvalsExpensesFinal[key].map(entry => {
				const matchingItem = group.find(
					item => item.id === entry.accountEmployeeExpenseSheetId && item.key === key
				);

				if (matchingItem) {
					// If all are approved, set the status to null
					if (allApproved) {
						return {
							...entry,
							status: null
						};
					}
					// If not all are approved, approve all (set status to 'approved')
					else {
						return {
							...entry,
							status: 'approved'
						};
					}
				}
				// If no match found, return the entry as is
				return entry;
			});

			return acc;
		}, {});

		// Update the state with the modified data
		setApprovalsExpensesFinal(updatedData);
	};

	//Every entry in the table will be rejected, giving the status rejected to the entries of the main data
	const rejectAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsExpensesFinal).reduce((acc, key) => {
			const allEntries = approvalsExpensesFinal[key].filter(
				entry => entry.employeeName === group[0].employeeName
			);

			const allRejected = allEntries.every(e => e.status == 'rejected');
			// Map over the entries and update the status accordingly
			acc[key] = approvalsExpensesFinal[key].map(entry => {
				const matchingItem = group.find(
					item => item.id === entry.accountEmployeeExpenseSheetId && item.key === key
				);

				if (matchingItem) {
					// If all are rejected, set the status to null
					if (allRejected) {
						return {
							...entry,
							status: null
						};
					}
					// If not all are rejected, approve all (set status to 'rejected')
					else {
						return {
							...entry,
							status: 'rejected'
						};
					}
				}
				// If no match found, return the entry as is
				return entry;
			});

			return acc;
		}, {});

		// Update the state with the modified data
		setApprovalsExpensesFinal(updatedData);
	};

	//Approve a single entry in the table giving a status approved to the entry
	const approveProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData: any = Object.keys(approvalsExpensesFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsExpensesFinal[key].map(entry =>
				entry.accountEmployeeExpenseSheetId === item.id && item.key === key
					? { ...entry, status: entry.status && entry.status === 'approved' ? null : 'approved' }
					: entry
			);
			return acc;
		}, {});

		// Update state with new data
		setApprovalsExpensesFinal(updatedData);
	};

	//Reject a single entry in the table giving a status rejected to the entry
	const rejectProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsExpensesFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsExpensesFinal[key].map(entry =>
				entry.accountEmployeeExpenseSheetId === item.id && item.key === key
					? { ...entry, status: entry.status && entry.status === 'rejected' ? null : 'rejected' }
					: entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsExpensesFinal(updatedData);
	};

	const commentEntryApproval = (item, comment) => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsExpensesFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsExpensesFinal[key].map(entry =>
				entry.accountEmployeeExpenseSheetId === item.id && item.key === key
					? { ...entry, comment: comment }
					: entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsExpensesFinal(updatedData);
	};
	//Removes uncessary tabs that the user don't have nothing to approve
	useEffect(() => {
		if (approvalsExpensesFinal) {
			let auxTabsExpenses = tabsExpenses;
			//Approvals -My Projects Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForProjectManagerSummarized?.length == 0) {
				auxTabsExpenses = auxTabsExpenses.filter(item => item.id !== 'myProjectsExpenseEntryApprovals');
			}
			//Approvals - My Team Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForTeamLeadSummarized?.length == 0) {
				auxTabsExpenses = auxTabsExpenses.filter(item => item.id !== 'myTeamTimeExpenseApprovals');
			}
			//Approvals - Specific External User Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForSpecificExternalUserSummarized?.length == 0) {
				auxTabsExpenses = auxTabsExpenses.filter(
					item => item.id !== 'specificExternalUserExpenseEntryApprovals'
				);
			}
			//Approvals - Specific Employee Expense Expense Approvals
			if (approvalsExpensesFinal?.approvalEntriesForSpecificEmployeeSummarized?.length == 0) {
				auxTabsExpenses = auxTabsExpenses.filter(
					item => item.id !== 'specificExternalUserExpenseEntryApprovals'
				);
			}
			//Approvals - Employee Manager Expense Entry Approvals
			if (approvalsExpensesFinal?.approvalEntriesForEmployeeManagerSummarized?.length == 0) {
				auxTabsExpenses = auxTabsExpenses.filter(item => item.id !== 'employeeManagerExpenseEntryApprovals');
			}

			setTabsExpenseFinal(auxTabsExpenses);
		}
	}, [approvalsExpensesFinal]);

	//! ------------------------ DETAILS APPROVALS --------------------------------------//
	//Gets all details of the respective approval of a user
	useEffect(() => {
		if (openDetailsModal) {
			Object.keys(approvalsExpensesFinal!).reduce((acc, key) => {
				approvalsExpensesFinal![key].map(entry => {
					// Find the item in the group that matches the entry
					const matchingItem = entry.accountEmployeeExpenseSheetId == entryIdToDetails.id;
					// If there's a match, update the entry; otherwise, return it as is
					if (matchingItem) {
						let amount = 0;
						entry.accountExpenseEntries.map(e => {
							if (e.reimburse == true) {
								amount += e.amount;
							}
						});
						setEntryIdToDetails({
							...entryIdToDetails,
							expenseSheetDate: entry.expenseSheetDate,
							employeeCode: entry.employeeCode,
							baseCurrencyCode: entry.baseCurrencyCode,
							amount: amount,
							accountExpenseEntries: entry.accountExpenseEntries
						});
					}
				});
			}, {});
		}
	}, [openDetailsModal]);

	//Prepares data for table time entry week view
	const tableWeekViewPayload = useMemo(
		() =>
			entryIdToDetails?.accountExpenseEntries &&
			entryIdToDetails?.accountExpenseEntries.map(e => {
				return {
					...e,
					reimburse: e.reimburse === true ? 'Yes' : 'No',
					isBillable: e.isBillable === true ? 'Yes' : 'No',
					netAmount: e.netAmount + ' ' + e.currencyCode,
					amountOnEUR: e.amountOnEUR + ' EUR',
					attachmentsLength: e.attachments.length,
					quantity: e.quantityFieldCaption + ' ' + e.quantity
				};
			}),
		[entryIdToDetails]
	);

	// TABLE COMPONENT - columns
	const tableWeekViewColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Date',
				id: 'accountExpenseEntryDate',
				width: '50%'
			},
			{ label: 'Project Name', id: 'projectName' },
			{ label: 'Expense Name', id: 'accountExpenseName' },
			{ label: 'Description', id: 'description' },
			{ label: 'Billable', id: 'isBillable' },
			{ label: 'Reimburse', id: 'reimburse' },
			{ label: 'Quantity', id: 'quantity' },
			{ label: 'Rate', id: 'rate' },
			{ label: 'Net Amount', id: 'netAmount' },
			{ label: 'Net Amount (EUR)', id: 'amountOnEUR' },
			{ label: 'PaymentStatus', id: 'paymentStatusState' },
			{
				label: 'Attachments',
				id: 'attachmentsLength',
				format: (row, text) =>
					text !== 0 ? (
						<div>
							<Button
								onClick={e => {
									setEntryAttachments(row.attachments);
									setOpenExpenseDownload(true);
								}}>
								{text}
							</Button>
						</div>
					) : (
						<div></div>
					)
			}
		],
		[]
	);
	// TABLE COMPONENT - columns
	const expenseAttachmentsColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Attachment Name',
				id: 'attachmentName',
				width: '50%'
			},
			{ label: 'File Name', id: 'attachmentLocalPath' },
			{
				label: '',
				id: 'download',
				format: (row, text) => (
					<IconButton
						style={{ color: 'rgba(0, 0, 0, 0.54)' }}
						onClick={e => {
							dispatch(
								downloadAttachmentApprovalsExpenses({
									expenseEntryId: row.accountExpenseEntryId,
									attachmentName: row.attachmentName,
									employeeId: row.createdByEmployeeId
								})
							);
						}}>
						<Download />
					</IconButton>
				)
			}
		],
		[]
	);

	//Prepares data for table time entry week view
	const expenseAttachmentsPayload = useMemo(
		() => entryAttachments && entryAttachments.map(e => e),
		[entryAttachments]
	);

	const isUpdateButtonDisabled = disableUpdateButton();
	const isLoading = approvalsExpensesFinal == null || loading;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{' '}
			<Grid
				container
				direction={'row'}
				sx={{
					justifyContent: 'space-evenly',
					alignItems: 'center'
				}}
				style={{ marginBottom: '30px' }}>
				<Grid item xs={10}>
					<Filters
						originalData={auxApprovalsExpenses}
						data={approvalsExpensesFinal}
						setData={setApprovalsExpensesFinal}
						setTabSelected={setTabSelected}></Filters>
				</Grid>
			</Grid>
			<TabHeader
				selected={tabSelected}
				className={classes.tabheader}
				handleSelected={(event: React.SyntheticEvent<Element, Event>, newValue: number) =>
					setTabSelected(newValue)
				}
				variant="scrollable"
				scrollButtons="auto">
				{tabsExpenseFinal.map((tab, index) => (
					<Tab key={`Resume_${tab.id}_tab_button`} label={tab.label} index={index} />
				))}
			</TabHeader>
			<Grid
				container
				direction="column"
				style={{
					marginBottom: '30px'
				}}>
				{/* My Projects Time Entry Approvals */}
				{approvalsExpensesFinal?.approvalEntriesForProjectManagerSummarized?.length > 0 &&
					tabsExpenseFinal[tabSelected]?.id === 'myProjectsExpenseEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForProjectManager && (
								<Grid item xs={12}>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForProjectManager}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* My Team Time Entry Approvals */}
				{approvalsExpensesFinal?.approvalEntriesForTeamLeadSummarized?.length > 0 &&
					tabsExpenseFinal[tabSelected].id === 'myTeamTimeExpenseApprovals' && (
						<>
							{groupedDataApprovalEntriesForTeamLeadSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForTeamLeadSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific External User Time Entry Approvals */}
				{approvalsExpensesFinal?.approvalEntriesForSpecificExternalUserSummarized?.length > 0 &&
					tabsExpenseFinal[tabSelected].id === 'specificExternalUserExpenseEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForSpecificExternalUserSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForSpecificExternalUserSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific Employee Time Entry Approvals */}
				{approvalsExpensesFinal?.approvalEntriesForSpecificEmployeeSummarized?.length > 0 &&
					tabsExpenseFinal[tabSelected].id === 'specificEmployeeExpenseEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForSpecificEmployeeSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForSpecificEmployeeSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Employee Manager Expense Entry Approvals */}
				{approvalsExpensesFinal?.approvalEntriesForEmployeeManagerSummarized?.length > 0 &&
					tabsExpenseFinal[tabSelected].id === 'employeeManagerExpenseEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForEmployeeManagerSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForEmployeeManagerSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
			</Grid>
			<Grid
				container
				direction="row"
				id={'footerButtonsID'}
				style={{
					position: 'sticky',
					bottom: 0,
					width: '100%',
					backgroundColor: 'white',
					paddingLeft: '30px',
					paddingRight: '30px',
					paddingBottom: '20px',
					paddingTop: '20px',
					boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Shadow for better visibility
					zIndex: 1000 // Ensures footer stays on top
				}}
				sx={{
					justifyContent: 'flex-end',
					alignItems: 'center'
				}}>
				<Grid item id={'footerLegendID'}>
					<Button
						disabled={isUpdateButtonDisabled}
						color="primary"
						variant="contained"
						onClick={() => {
							updateExpenseEntryApprovals();
						}}>
						Update Expense Entry Approvals
					</Button>
				</Grid>
			</Grid>
			<Dialog
				title={<div>Expense Details</div>}
				maxWidth="xl"
				modal={{
					open: Boolean(openDetailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenDetailsModal(false);
						}
					},
					content: (
						<>
							{loading && <LoadingOverlay />}
							{entryIdToDetails && (
								<Grid container direction="column" spacing={2} style={{ width: '50%' }}>
									<Grid item>
										<Grid container direction="row">
											<Grid item xs={6}>
												<Typography>
													<span style={{ fontWeight: 'bold' }}>Employee Code: </span>
													{entryIdToDetails?.employeeCode}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography>
													<span style={{ fontWeight: 'bold' }}>Employee Name: </span>
													{entryIdToDetails?.employeeName}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography>
													<span style={{ fontWeight: 'bold' }}>Reimbursement Currency: </span>
													{entryIdToDetails?.baseCurrencyCode}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography>
													<span style={{ fontWeight: 'bold' }}>Date: </span>
													{entryIdToDetails?.date}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<span style={{ fontWeight: 'bold' }}>Total Reimbursement: </span>
												{new Intl.NumberFormat('pt-PT', {
													style: 'currency',
													currency: entryIdToDetails?.baseCurrencyCode || 'EUR' // Default to EUR if no currency code is provided
												}).format(entryIdToDetails?.amount || 0)}{' '}
												{/* Default amount to 0 if it's undefined */}
											</Grid>
											<Grid item xs={6}>
												<Typography>
													<span style={{ fontWeight: 'bold' }}>Description:</span>{' '}
													{entryIdToDetails?.description}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12}>
										{tableWeekViewPayload?.length > 0 && (
											<>
												<div
													style={{
														marginLeft: '-20px',
														marginTop: '20px',
														transform: 'scale(0.93)'
													}}>
													<Table
														tableData={tableWeekViewPayload}
														columns={tableWeekViewColumns}></Table>
												</div>
											</>
										)}
									</Grid>
								</Grid>
							)}
						</>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Close',
						color: 'primary',
						variant: 'text',
						onClick: () => {
							setOpenDetailsModal(false);
						}
					}
				]}
			/>
			<Dialog
				title={<div>Expense Attachments</div>}
				modal={{
					open: Boolean(openExpenseDownload),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenExpenseDownload(false);
						}
					},
					content: (
						<>
							{loading && <LoadingOverlay />}
							<Table tableData={expenseAttachmentsPayload} columns={expenseAttachmentsColumns}></Table>
						</>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Close',
						color: 'primary',
						variant: 'text',
						onClick: () => {
							setOpenExpenseDownload(false);
						}
					}
				]}
			/>
		</>
	);
};
export default withLayout(Expense);
