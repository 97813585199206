//* EXTERNAL LIBS
import React, { useEffect, FC, useMemo, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Add } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, CollapseTable, TabHeader, Tab, Dialog, Table } from 'xpand-ui/core';
import { Checkbox } from 'xpand-ui/forms';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { TimesheetProps } from '.';
import { decimalToHours } from '../../../PersonalInfo/MyTimeTracking/MyTimesheets/utils';

import { IUserProfile } from 'typings/store/personalInfoTypes';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getApprovalsTimesheet, getApprovalsTimesheetPeriodDetails } from 'store/personalInfo/thunks';
import { convertMinutesToTime, formatDate, formatDateFinal, tabsTimesheet } from '../utils';
import { IUserToken } from 'typings/store/generalTypes';
import { ITableColumn } from 'typings/store/ComponentLib';
import { format } from 'date-fns';

//* COMPONENT INTERFACES
interface ITimesheet extends TimesheetProps {
	isAdmin: string;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
}

//* COMPONENT
const Timesheet: FC<ITimesheet> = ({ loggedUser }) => {
	const classes = useStyles();
	const approvalsTimesheet = useSelector(state => state.personalInfo.approvalsTimesheet);
	const approvalsTimesheetDetails = useSelector(state => state.personalInfo.approvalsTimesheetPeriodDetails);
	const loading = useSelector(state => state.personalInfo.loading);
	const [approvalsTimesheetFinal, setApprovalsTimesheetFinal] = useState();
	//Approval Details
	const [openDetailsModal, setOpenDetailsModal] = useState(false);
	const [entryIdToDetails, setEntryIdToDetails] = useState();
	const [timeEntryWeekTableData, setTimeEntryWeekTableData] = useState();
	const [commentsListTableData, setCommentsListTableData] = useState();
	const [showAll, setShowAll] = useState(false);

	const dispatch = useDispatch();
	const [tabSelected, setTabSelected] = useState(0);
	const [tabsTimesheetFinal, setTabsTimesheetFinal] = useState(tabsTimesheet);
	//Approvals -My Projects Time Entry Approvals
	const [groupedDataApprovalEntriesForProjectManager, setGroupedDataApprovalEntriesForProjectManager] =
		useState<any>();
	//Approvals - My Team Time Entry Approvals
	const [groupedDataApprovalEntriesForTeamLeadSummarized, setGroupedDataApprovalEntriesForTeamLeadSummarized] =
		useState<any>();
	//Approvals - Project Manager Time Off Approvals
	const [groupedDataAccountProjectsSpecificEmployeeTimeOff, setGroupedDataAccountProjectsSpecificEmployeeTimeOff] =
		useState<any>();
	//Approvals - Specific External User Time Entry Approvals
	const [
		groupedDataApprovalEntriesForSpecificExternalUserSummarized,
		setGroupedDataApprovalEntriesForSpecificExternalUserSummarized
	] = useState<any>();
	//Approvals - Specific Employee Time Entry Approvals
	const [
		groupedDataApprovalEntriesForSpecificEmployeeSummarized,
		setGroupedDataApprovalEntriesForSpecificEmployeeSummarized
	] = useState<any>();

	//Approvals -Specific Employee Time Off Approvals
	const [
		groupedDataApprovalEntriesSpecificEmployeeForTimeOff,
		setGroupedDataApprovalEntriesSpecificEmployeeForTimeOff
	] = useState<any>();

	const rowsHeaderApprovalEntriesForProjectManager = useMemo(
		() => ['Period', 'Billable Hours', ' Non-Billable Hours', 'Total Hours'],
		[]
	);

	//! ------------------------ TIMESHEET APPROVALS --------------------------------------//
	//Gets all timesheet approvals of the logged user
	useEffect(() => {
		dispatch(
			getApprovalsTimesheet({
				isFirstLoad: false,
				username: 'lbgo',
				includeDateRange: false
			})
		);
	}, []);

	//Data os copied to a variable to be manipulated
	useEffect(() => {
		setApprovalsTimesheetFinal(approvalsTimesheet);
	}, [approvalsTimesheet]);

	//Fill every table with the respective information
	useEffect(() => {
		//Approvals -My Projects Time Entry Approvals
		if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized) {
			setGroupedDataApprovalEntriesForProjectManager(
				Object.values(
					approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized?.reduce((acc, item) => {
						if (!acc[item.employeeName]) {
							acc[item.employeeName] = [];
						}

						const period =
							formatDate(item.timeEntryStartDate) + ' to ' + formatDateFinal(item.timeEntryEndDate);
						acc[item.employeeName].push({
							employeeName: item.employeeName,
							id: item.accountEmployeeTimeEntryPeriodId,
							period: period,
							status: item.status,
							comment: item.comment || null,
							billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes) + ' Total',
							nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes) + ' Total',
							totalMinutes: convertMinutesToTime(item.totalMinutes) + ' Total'
						});
						return acc;
					}, {})
				)
			);
			//Approvals - My Team Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized) {
				setGroupedDataApprovalEntriesForTeamLeadSummarized(
					Object.values(
						approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized?.reduce((acc, item) => {
							if (!acc[item.employeeName]) {
								acc[item.employeeName] = [];
							}

							const period =
								formatDate(item.timeEntryStartDate) + ' to ' + formatDateFinal(item.timeEntryEndDate);
							acc[item.employeeName].push({
								employeeName: item.employeeName,
								id: item.accountEmployeeTimeEntryPeriodId,
								period: period,
								status: item.status,
								comment: item.comment || null,
								billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes) + ' Total',
								nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes) + ' Total',
								totalMinutes: convertMinutesToTime(item.totalMinutes) + ' Total'
							});
							return acc;
						}, {})
					)
				);
			}
			//Approvals - Project Manager Time Off Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff) {
				setGroupedDataAccountProjectsSpecificEmployeeTimeOff(
					Object.values(
						approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff?.reduce((acc, item) => {
							if (!acc[item.employeeName]) {
								acc[item.employeeName] = [];
							}

							const period =
								formatDate(item.timeEntryStartDate) + ' to ' + formatDateFinal(item.timeEntryEndDate);
							acc[item.employeeName].push({
								employeeName: item.employeeName,
								id: item.accountEmployeeTimeEntryPeriodId,
								period: period,
								status: item.status,
								comment: item.comment || null,
								billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes) + ' Total',
								nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes) + ' Total',
								totalMinutes: convertMinutesToTime(item.totalMinutes) + ' Total'
							});
							return acc;
						}, {})
					)
				);
			}
			//Approvals - Specific External User Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized) {
				setGroupedDataApprovalEntriesForSpecificExternalUserSummarized(
					Object.values(
						approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized?.reduce(
							(acc, item) => {
								if (!acc[item.employeeName]) {
									acc[item.employeeName] = [];
								}

								const period =
									formatDate(item.timeEntryStartDate) +
									' to ' +
									formatDateFinal(item.timeEntryEndDate);
								acc[item.employeeName].push({
									employeeName: item.employeeName,
									id: item.accountEmployeeTimeEntryPeriodId,
									period: period,
									status: item.status,
									comment: item.comment || null,
									billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes) + ' Total',
									nonBillableTotalMinutes:
										convertMinutesToTime(item.nonBillableTotalMinutes) + ' Total',
									totalMinutes: convertMinutesToTime(item.totalMinutes) + ' Total'
								});
								return acc;
							},
							{}
						)
					)
				);
			}
			//Approvals - Specific Employee Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized) {
				setGroupedDataApprovalEntriesForSpecificEmployeeSummarized(
					Object.values(
						approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized?.reduce((acc, item) => {
							if (!acc[item.employeeName]) {
								acc[item.employeeName] = [];
							}

							const period =
								formatDate(item.timeEntryStartDate) + ' to ' + formatDateFinal(item.timeEntryEndDate);
							acc[item.employeeName].push({
								employeeName: item.employeeName,
								id: item.accountEmployeeTimeEntryPeriodId,
								period: period,
								status: item.status,
								comment: item.comment || null,
								billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes) + ' Total',
								nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes) + ' Total',
								totalMinutes: convertMinutesToTime(item.totalMinutes) + ' Total'
							});
							return acc;
						}, {})
					)
				);
			}
			//Approvals -Specific Employee Time Off Approvals
			if (approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff) {
				setGroupedDataApprovalEntriesSpecificEmployeeForTimeOff(
					Object.values(
						approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff?.reduce((acc, item) => {
							if (!acc[item.employeeName]) {
								acc[item.employeeName] = [];
							}

							const period =
								formatDate(item.timeEntryStartDate) + ' to ' + formatDateFinal(item.timeEntryEndDate);
							acc[item.employeeName].push({
								employeeName: item.employeeName,
								id: item.accountEmployeeTimeEntryPeriodId,
								period: period,
								status: item.status,
								comment: item.comment || null,
								billableTotalMinutes: convertMinutesToTime(item.billableTotalMinutes) + ' Total',
								nonBillableTotalMinutes: convertMinutesToTime(item.nonBillableTotalMinutes) + ' Total',
								totalMinutes: convertMinutesToTime(item.totalMinutes) + ' Total'
							});
							return acc;
						}, {})
					)
				);
			}
		}
	}, [approvalsTimesheetFinal]);

	//Every entry in the table will be approved, giving the status approved to the entries of the main data
	const approveAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Check if at least one entry is approved
			const atLeastOneApproved = approvalsTimesheetFinal[key].some(entry => entry.status === 'approved');
			// Check if all entries are approved
			const allApproved = approvalsTimesheetFinal[key].every(entry => entry.status === 'approved');

			acc[key] = approvalsTimesheetFinal[key].map(entry => {
				// Find the item in the group that matches the entry
				const matchingItem = group.find(item => item.id === entry.accountEmployeeTimeEntryPeriodId);

				// If there's a match, update the entry; otherwise, return it as is
				if (matchingItem) {
					return {
						...entry,
						// If all are approved, set status to null; if at least one is approved, set all to 'approved'
						status: allApproved ? null : 'approved'
					};
				} else {
					return entry;
				}
			});

			// If none are approved, set all to 'approved'
			if (!atLeastOneApproved) {
				acc[key] = acc[key].map(entry => ({
					...entry,
					status: 'approved'
				}));
			}

			return acc;
		}, {});

		setApprovalsTimesheetFinal(updatedData);
	};

	//Every entry in the table will be rejected, giving the status rejected to the entries of the main data
	const rejectAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Check if at least one entry is rejected
			const atLeastOneRejected = approvalsTimesheetFinal[key].some(entry => entry.status === 'rejected');
			// Check if all entries are rejected
			const allRejected = approvalsTimesheetFinal[key].every(entry => entry.status === 'rejected');

			acc[key] = approvalsTimesheetFinal[key].map(entry => {
				// Find the item in the group that matches the entry
				const matchingItem = group.find(item => item.id === entry.accountEmployeeTimeEntryPeriodId);

				// If there's a match, update the entry; otherwise, return it as is
				if (matchingItem) {
					return {
						...entry,
						// If all are rejected, set status to null; if at least one is rejected, set all to 'rejected'
						status: allRejected ? null : 'rejected'
					};
				} else {
					return entry;
				}
			});

			// If none are rejected, set all to 'rejected'
			if (!atLeastOneRejected) {
				acc[key] = acc[key].map(entry => ({
					...entry,
					status: 'rejected'
				}));
			}

			return acc;
		}, {});

		setApprovalsTimesheetFinal(updatedData);
	};

	//Approve a single entry in the table giving a status approved to the entry
	const approveProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimesheetFinal[key].map(entry =>
				entry.accountEmployeeTimeEntryPeriodId === item.id
					? { ...entry, status: entry.status && entry.status === 'approved' ? null : 'approved' }
					: entry
			);
			return acc;
		}, {});

		// Update state with new data
		setApprovalsTimesheetFinal(updatedData);
	};

	//Reject a single entry in the table giving a status rejected to the entry
	const rejectProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimesheetFinal[key].map(entry =>
				entry.accountEmployeeTimeEntryPeriodId === item.id
					? { ...entry, status: entry.status && entry.status === 'rejected' ? null : 'rejected' }
					: entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsTimesheetFinal(updatedData);
	};

	const commentEntryApproval = (item, comment) => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsTimesheetFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimesheetFinal[key].map(entry =>
				entry.accountEmployeeTimeEntryPeriodId === item.id ? { ...entry, comment: comment } : entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsTimesheetFinal(updatedData);
	};
	//Removes uncessary tabs that the user don't have nothing to approve
	useEffect(() => {
		if (approvalsTimesheetFinal) {
			let auxTabsTimesheet = tabsTimesheetFinal;
			//Approvals -My Projects Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'myProjectsTimeEntryApprovals');
			}
			//Approvals - My Team Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'myTeamTimeEntryApprovals');
			}
			//Approvals - Project Manager Time Off Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'projectManagerTimeOffApprovals');
			}
			//Approvals - Specific External User Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(
					item => item.id !== 'specificExternalUserTimeEntryApprovals'
				);
			}
			//Approvals - Specific Employee Time Entry Approvals
			if (approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'specificEmployeeTimeEntryApprovals');
			}
			//Approvals -Specific Employee Time Off Approvals
			if (approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff.length == 0) {
				auxTabsTimesheet = auxTabsTimesheet.filter(item => item.id !== 'specificEmployeeTimeOffApprovals');
			}
			setTabsTimesheetFinal(auxTabsTimesheet);
		}
	}, [approvalsTimesheetFinal]);

	//! ------------------------ DETAILS APPROVALS --------------------------------------//
	//Gets all details of the respective approval of a user
	useEffect(() => {
		if (openDetailsModal) {
			Object.keys(approvalsTimesheetFinal!).reduce((acc, key) => {
				approvalsTimesheetFinal![key].map(entry => {
					// Find the item in the group that matches the entry
					const matchingItem = entry.accountEmployeeTimeEntryPeriodId === entryIdToDetails;

					// If there's a match, update the entry; otherwise, return it as is
					if (matchingItem) {
						dispatch(
							getApprovalsTimesheetPeriodDetails({
								accountEmployeeId: entry!.timeEntryAccountEmployeeId,
								startDate: entry!.timeEntryStartDate,
								endDate: entry!.timeEntryEndDate
							})
						);
					}
				});
			}, {});
		}
	}, [openDetailsModal]);

	//Prepares data for table time entry week view
	const tableWeekViewPayload = useMemo(
		() => timeEntryWeekTableData && timeEntryWeekTableData.map(e => e),
		[timeEntryWeekTableData]
	);

	//Prepares data for table comments List
	const tableCommentsListPayload = useMemo(
		() => commentsListTableData && commentsListTableData.map(e => e),
		[commentsListTableData]
	);

	// Helper function to get day of the week from timeEntryDate
	const getDayOfWeek = dateString => {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const date = new Date(dateString);
		return days[date.getDay()];
	};

	//Creates the arrays responsible to contain every info for the details tables
	useEffect(() => {
		if (approvalsTimesheetDetails) {
			let auxForTableWeekView = [];
			let auxForCommentsListTable = [];

			const matchingEntry = Object.keys(approvalsTimesheetFinal!).reduce((acc, key) => {
				// Iterate over each entry within the current key
				const foundEntry = approvalsTimesheetFinal![key].find(entry => {
					// Check if the entry's accountProjectId matches the desired project ID
					return entry.accountEmployeeTimeEntryPeriodId === entryIdToDetails;
				});

				// If an entry is found, return it
				if (foundEntry) {
					return foundEntry;
				}

				return acc; // Continue reducing if no match is found
			}, undefined); // Initial value for reduce is undefined

			const groupedByProject = approvalsTimesheetDetails.accountEmployeeIdAndDateRangeWithStatus.reduce(
				(acc, item) => {
					entryIdToDetails;
					// If the project name does not exist in the accumulator, create a new array for it
					if (!acc[item.projectName]) {
						acc[item.projectName] = [];
					}

					// Push the current item into the array for the respective project name
					acc[item.projectName].push(item);

					return acc;
				},
				{}
			);
			for (let project in groupedByProject) {
				groupedByProject[project].sort((a, b) => new Date(a.timeEntryDate) - new Date(b.timeEntryDate));
			}

			for (let project in groupedByProject) {
				let auxLine = {
					projectName: groupedByProject[project][0].projectName,
					taskName: groupedByProject[project][0].taskName,
					costCenter: groupedByProject[project][0].accountCostCenter,
					workType: groupedByProject[project][0].accountWorkType,
					attachments: groupedByProject[project][0].attachments
				};
				let auxCommentsLine = {};

				groupedByProject[project].map(e => {
					if (getDayOfWeek(e.timeEntryDate) == 'Monday') {
						auxLine = {
							...auxLine,
							mon:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Tuesday') {
						auxLine = {
							...auxLine,
							tue:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Wednesday') {
						auxLine = {
							...auxLine,
							wed:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Thursday') {
						auxLine = {
							...auxLine,
							thu:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Friday') {
						auxLine = {
							...auxLine,
							fri:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Saturday') {
						auxLine = {
							...auxLine,
							sat:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}
					if (getDayOfWeek(e.timeEntryDate) == 'Sunday') {
						auxLine = {
							...auxLine,
							sun:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							projectId: e.accountProjectId
						};
					}

					if (e.description) {
						auxCommentsLine = {
							projectName: e.projectName,
							taskName: e.taskName,
							date: format(new Date(e.timeEntryDate), 'dd-MM-yyyy'),
							total:
								decimalToHours(e.hours).hours.toString().padStart(2, '0') +
								':' +
								decimalToHours(e.hours).minutes.toString().padStart(2, '0'),
							description: e.description,
							projectId: e.accountProjectId
						};
					}
					//}
				});

				if (!showAll) {
					if (auxLine.projectId === matchingEntry?.accountProjectId)
						auxForTableWeekView = [...auxForTableWeekView, auxLine];
					if (auxCommentsLine.projectId === matchingEntry?.accountProjectId)
						auxForCommentsListTable = [...auxForCommentsListTable, auxCommentsLine];
				} else {
					auxForTableWeekView = [...auxForTableWeekView, auxLine];
					auxForCommentsListTable = [...auxForCommentsListTable, auxCommentsLine];
				}
			}

			setTimeEntryWeekTableData(auxForTableWeekView);
			setCommentsListTableData(auxForCommentsListTable);
		}
	}, [approvalsTimesheetDetails, showAll]);

	// TABLE COMPONENT - columns
	const tableWeekViewColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Project Name',
				id: 'projectName',
				width: '50%'
			},
			{ label: 'Task Name', id: 'taskName' },
			{ label: 'Cost Center', id: 'costCenter' },
			{ label: 'Work Type', id: 'workType' },
			{ label: 'Attachments', id: 'attachments' },
			{ label: 'Mon', id: 'mon' },
			{ label: 'Tue', id: 'tue' },
			{ label: 'Wed', id: 'wed' },
			{ label: 'Thu', id: 'thu' },
			{ label: 'Fri', id: 'fri' },
			{ label: 'Sat', id: 'Sat' },
			{ label: 'Sun', id: 'sun' }
		],
		[approvalsTimesheetDetails]
	);
	const tableCommentsListColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Project Name',
				id: 'projectName'
			},
			{ label: 'Task Name', id: 'taskName' },
			{ label: 'Date', id: 'date' },
			{ label: 'Total Time', id: 'total' },
			{ label: 'Description', id: 'description' }
		],
		[approvalsTimesheetDetails]
	);

	const isLoading = approvalsTimesheetFinal == null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<TabHeader
				selected={tabSelected}
				className={classes.tabheader}
				handleSelected={(event: React.SyntheticEvent<Element, Event>, newValue: number) =>
					setTabSelected(newValue)
				}
				variant="scrollable"
				scrollButtons="auto">
				{tabsTimesheetFinal.map((tab, index) => (
					<Tab key={`Resume_${tab.id}_tab_button`} label={tab.label} index={index} />
				))}
			</TabHeader>
			<Grid container direction="column">
				{/* My Projects Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForProjectManagerSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected].id === 'myProjectsTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForProjectManager && (
								<Grid item xs={12}>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForProjectManager}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* My Team Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForTeamLeadSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected].id === 'myTeamTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForTeamLeadSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForTeamLeadSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Project Manager Time Off Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForProjectManagerTimeOff?.length > 0 &&
					tabsTimesheetFinal[tabSelected].id === 'projectManagerTimeOffApprovals' && (
						<>
							{groupedDataAccountProjectsSpecificEmployeeTimeOff && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataAccountProjectsSpecificEmployeeTimeOff}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific External User Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForSpecificExternalUserSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected].id === 'specificExternalUserTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForSpecificExternalUserSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForSpecificExternalUserSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific Employee Time Entry Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesForSpecificEmployeeSummarized?.length > 0 &&
					tabsTimesheetFinal[tabSelected].id === 'specificEmployeeTimeEntryApprovals' && (
						<>
							{groupedDataApprovalEntriesForSpecificEmployeeSummarized && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesForSpecificEmployeeSummarized}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Specific Employee Time Off Approvals */}
				{approvalsTimesheetFinal?.approvalEntriesSpecificEmployeeForTimeOff?.length > 0 &&
					tabsTimesheetFinal[tabSelected].id === 'projectManagerTimeOffApprovals' && (
						<>
							{groupedDataApprovalEntriesSpecificEmployeeForTimeOff && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntriesForProjectManager}
										data={groupedDataApprovalEntriesSpecificEmployeeForTimeOff}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
			</Grid>
			<Dialog
				title="Period Details"
				maxWidth="lg"
				modal={{
					open: Boolean(openDetailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenDetailsModal(false);
						}
					},
					content: (
						<>
							{loading && <LoadingOverlay />}
							<Grid container>
								<Grid item xs={12}>
									<Checkbox
										color="primary"
										label="Show All"
										additionalOnChange={() => {
											setShowAll(!showAll);
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									{tableWeekViewPayload?.length > 0 && (
										<>
											<Typography
												style={{
													borderRadius: '5px',
													backgroundColor: '#3F80EF',
													color: 'white',
													height: '40px',
													display: 'flex',
													alignItems: 'center', // Vertically centers the content
													justifyContent: 'start', // If you want it aligned to the left, otherwise use 'center'
													paddingLeft: '30px' // Optional: You can control horizontal padding instead of using margin
												}}>
												<div>Time Entry Week View</div>
											</Typography>

											<Table
												tableData={tableWeekViewPayload}
												columns={tableWeekViewColumns}></Table>
										</>
									)}
								</Grid>
								<Grid item xs={12}>
									{tableCommentsListPayload?.length > 0 && (
										<>
											<Typography
												style={{
													borderRadius: '5px',
													backgroundColor: '#3F80EF',
													color: 'white',
													height: '40px',
													display: 'flex',
													alignItems: 'center', // Vertically centers the content
													justifyContent: 'start', // If you want it aligned to the left, otherwise use 'center'
													paddingLeft: '30px' // Optional: You can control horizontal padding instead of using margin
												}}>
												Comments List
											</Typography>
											<Table
												tableData={tableCommentsListPayload}
												columns={tableCommentsListColumns}></Table>
										</>
									)}
								</Grid>
							</Grid>
						</>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Close',
						color: 'primary',
						variant: 'text',
						onClick: () => {
							setOpenDetailsModal(false);
						}
					}
				]}
			/>
		</>
	);
};
export default withLayout(Timesheet);
