export const convertMinutesToTime = minutes => {
	const hours = Math.floor(minutes / 60) % 24;
	const mins = minutes % 60;

	return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

const formatDayWithSuffix = day => {
	if (day > 3 && day < 21) return `${day}th`; // for 11th, 12th, 13th, etc.
	switch (day % 10) {
		case 1:
			return `${day}st`;
		case 2:
			return `${day}nd`;
		case 3:
			return `${day}rd`;
		default:
			return `${day}th`;
	}
};

export const formatDate = dateString => {
	const date = new Date(dateString);
	const day = formatDayWithSuffix(date.getDate());
	const month = date.toLocaleString('en-US', { month: 'long' });

	return `${month} ${day} `;
};
export const formatDateFinal = dateString => {
	const date = new Date(dateString);
	const day = formatDayWithSuffix(date.getDate());
	const month = date.toLocaleString('en-US', { month: 'long' });

	return `${month} ${day}, ${date.getFullYear()} `;
};

export const tabsTimesheet = [
	{
		id: 'myTeamTimeEntryApprovals',
		label: 'My Team Time Entry Approvals'
	},
	{
		id: 'myProjectsTimeEntryApprovals',
		label: 'My Projects Time Entry Approvals'
	},
	{
		id: 'specificEmployeeTimeEntryApprovals',
		label: 'Specific Employee Time Entry Approvals'
	},
	{
		id: 'specificExternalUserTimeEntryApprovals',
		label: 'Specific External User Time Entry Approvals'
	},
	{
		id: 'specificEmployeeTimeOffApprovals',
		label: 'Specific Employee Time Off Approvals'
	},
	{
		id: 'projectManagerTimeOffApprovals',
		label: 'Project Manager Time Off Approvals'
	}
];
export const tabsExpenses = [
	{
		id: 'myTeamTimeExpenseApprovals',
		label: 'My Team Expense Entry Approvals'
	},
	{
		id: 'myProjectsExpenseEntryApprovals',
		label: 'My Projects Expense Entry Approvals'
	},
	{
		id: 'specificEmployeeExpenseEntryApprovals',
		label: 'Specific Employee Expense Entry Approvals'
	},
	{
		id: 'specificExternalUserExpenseEntryApprovals',
		label: 'Specific External User Expense Entry Approvals'
	},
	{
		id: 'employeeManagerExpenseEntryApprovals',
		label: 'Employee Manager Expense Entry Approvals'
	}
];
export const tabsTimeoff = [
	{
		id: 'projectManagerTimeOffApprovals',
		label: 'Project Manager Time Off Approvals'
	},
	{
		id: 'employeeManagerTimeOffApprovals',
		label: 'Employee Manager Time Off Approvals'
	},
	{
		id: 'specificEmployeeTimeOffApprovals',
		label: 'Specific Employee Time Off Approvals'
	},
	{
		id: 'teamLeadTimeOffApprovals',
		label: 'Team Lead Time Off Approvals'
	}
];
